<template>
  <div>
    <div class="loginbj flex flex-center w-screen h-screen">
      <div class="loginContent ">
        <div class="rightContent">
          <div class="title">欢迎登录</div>
          <el-form ref='ruleForm' label-position='right' :model='form' :rules='rules' autocomplete="new-password">
            <el-form-item class='forit forit02 flex' prop='name'>
              <el-input v-model='form.name' autocomplete="off" class="userinp" placeholder='请输入用户名'>
                <template #prefix>
                  <div class="prefixUser"></div>
                  <div class="prefixUserRight"></div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item class='forit forit02 flex ' prop='pwd'  style="margin-bottom: 0px;">
              <el-input v-model='form.pwd' autocomplete="new-password" show-password class="userinp" placeholder='请输入密码'>
                <template #prefix>
                  <div class="prefixPass"></div>
                  <div class="prefixUserRight"></div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop='checked' class='f'>
              <div style='width: 100%;' class='flex justify-between mt-5 '>
                <span style='color:#B3B3B3' class="cursor-pointer" @click='forget'>忘记密码？</span>
                <span class='span_primary cursor-pointer' @click='register'>立即注册</span>
              </div>
            </el-form-item>
            <div class=" mt-20">
              <el-button type='primary' class='subm' @click='onSubmit'>登录</el-button>
            </div>
          </el-form>
        </div>
      </div>

      <!-- <div class="bottomInfo">
        山西合邦电力科技有限公司版权所有
      </div> -->
    </div>
    <!-- <div class="fixedBottom">
      <el-link :underline="true" type="primary" @click="goWebsite">合邦能源网 <el-icon class="ml-1"><Right/></el-icon></el-link>
    </div> -->

  </div>


</template>

<script setup>
import { defineComponent, reactive, ref } from 'vue'
import {Right} from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import { validate, setLocal } from '../../utils/formExtend'
import { mapMutations } from 'vuex'
import router from '@/router/index'
import { login } from '@/api/index'
import '@/registerMicroAppsConfig'
import { defineEmits } from 'vue'
import JSEncrypt from 'jsencrypt';
const emit = defineEmits(['isSuccess','register'])
const storeMutation = mapMutations(['SET_ACCESSTOKEN']);
let form = reactive({
  name: '',
  pwd: '',
  owner: 'pta'
})
const loginApi = (param) => {
  login(param).then(res => {
    ElMessage.success('登录成功')
    const token = res.data.token
    setLocal('token', token, 1000 * 60 * 60)
    // 告诉父元素登录成功了
    emit('isSuccess', 'login')
    //router.push(router.currentRoute.value.query.f)

  }).catch(error => {
    localStorage.removeItem('name')

  })

}
// 去goWebsite
const goWebsite = () => {
    window.open('https://pxap.sxhbdlkj.com/', '_blank')
}
const ruleForm = ref(null)
const enterSubmit = (e) => {
  if (e.key === 'Enter') {
    onSubmit()
  }
}
// 忘记密码
const forget = () => {
  router.push("/retrievePassword")
  emit("retrievePassword")
}
// 注册
const register = () => {
  router.push("/register")
  emit("register")
}
const onSubmit = async () => {
  let { name, pwd } = form
  if (!await validate(ruleForm)) return
  const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCk2nvbv1V92I7wodOVGhAezEHrRHsPj3XQRzLEUYzhkXSqTAVDJrC3fS8c8bz2NDIROr5XzOmgTVrDIFL/kYSjc79Q+65684pYMN2R+pZKplB8hlUhJ5LscrES43TquLM6Oycp98D64tqtGm/XIURoi4b9iDQpVFNFqk3O1K919wIDAQAB';
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  const encName = encrypt.encrypt(name);
  const encPassword = encrypt.encrypt(pwd);
  localStorage.setItem('name',name)
  await loginApi({ username: encName, password: encPassword, owner: 'pta' })
  // await loginApi({username: name, password: pwd, owner: 'pta'})

}

const rules = reactive({
  name: [
    {
      validator: (rule, value, callback) => {
        if (!value) {
          return callback(new Error('用户名不能为空'))
        }
        callback()
      }, trigger: 'blur'
    },
    {pattern: /^[a-zA-Z0-9_]+$/, message: '请输入由英文字母、下划线、数字构成的用户名', trigger: ['change','blur']}
  ],
  pwd: [
    {
      validator: (rule, value, callback) => {
        if (!value) {
          return callback(new Error('密码不能为空'))
        }
        callback()
      }, trigger: 'blur'
    }
  ]
})

</script>

<style lang='less' scoped>
.flex-center {
  align-items: center;
  justify-content: center;
}
.span_primary{
  color:#3381FF;
}
.loginbj {
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/img/User/login01.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;

  .loginContent {
    width: 100%;
    background: rgba(255, 255, 255, 0.17);
    display: flex;
    justify-content: flex-end;
    padding-right:240px;
    .rightContent {
      background: rgba(255, 255, 255, 0.91);
      padding: 0px 33px 0px 33px;
      text-align: center;
      width: 512px;
      height: 600px;
      background: #FFFFFF;
      box-shadow: 0px 0px 18px 2px rgba(64,147,250,0.1);
      border-radius: 4px;

      .title {
        text-align: center;
        position:relative;
        line-height:50px;
        margin:80px auto;
        font-size: 34px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #000000;
      }
      .title::after{
        content:' ';
        position: absolute;
        height:2px;width:64px;
        bottom:0;
        background: #1890FF;
        left:50%;
        transform: translate(-50%,0);
      }

      .forit {
        height: 50px;
        box-sizing: border-box;
        margin: 0 auto 40px;
        border: 1px solid #DCDCDC;
        border-radius: 4px;

        .el-input {
          width: 100%;
          height: 100%;
        }

        ::v-deep(.el-input__inner) {
          width: 100%;
          height: 100%;
          border: none !important;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 300;
          color: #333333 !important;
        }

        ::v-deep(.el-form-item__error) {
          top: 56px;
          left: 20px;
        }

        ::v-deep(.el-input__wrapper) {
          box-shadow: none;
          width: 100%;
          height: 100%;
          border: none;
        }

        .userinp {
          width: 100%;
          height: 90%;

          .prefixUser {
            width: 22px;
            height: 22px;
            margin-right: 10px;
            background-image: url('../../assets/img/User/username.png');
            background-repeat: no-repeat;
          }

          .prefixUserRight {
            width: 1px;
            height: 34px;
            background: #DCDCDC;
            margin-right: 16px;
          }

          .prefixPass {
            width: 22px;
            height: 22px;
            margin-right: 10px;
            background-image: url('../../assets/img/User/password.png');
            background-repeat: no-repeat;
          }
        }

        .code {
          width: 30%;
          text-align: right;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #3381FF;
          line-height: 79px;
          cursor: pointer;
          letter-spacing: 1px;
        }
      }

      //.forit02 {
      //  ::v-deep(.el-form-item__error) {
      //    top: 45px;
      //    left: 20px;
      //  }
      //}

      .subm {
        width: 100%;
        height: 44px;
        background: #1890FF;
        border-radius: 4px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 33px;
        margin: 0 auto 0;
        text-align: center;
        display: flex;
        justify-content: center;
      }

      .checkContent {
        width: 495px;
        margin: 70px auto 30px;
        text-align: left;
      }
    }
  }

  .bottomInfo {
    height: 16px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #E7FBFF;
    line-height: 30px;
    opacity: 0.74;
    position: absolute;
    bottom: 44px;
    left: auto;
    right: auto;
  }

}
.fixedBottom{
  position: fixed;
  bottom:10px;
  left:50%;
}
</style>
