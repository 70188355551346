import { createStore } from 'vuex';

// import Vue from 'vue';         // CDN引入vue.js  注释此行
// import Vuex from 'vuex';       // CDN引入vuex.js  注释此行
// Vue.use(Vuex);                 // CDN引入无需启用

const state = {
  accessToken: '',
  userName: '',
  breadcrumbList: [],
  funList: [],                // 启用的功能列表
  menuId:JSON.parse(sessionStorage.getItem("SET_MENUID_SESSION")) || '',                  // 选中状态的menuId
  menuGroup:JSON.parse(sessionStorage.getItem("SET_MENUID_SESSION_GROUP")) ||[],
  headerImg:sessionStorage.getItem("HEADERIMG")||'',
};
const getters = {};
const actions = {};
const mutations = {
  // 设置headerImg
  SET_HEADERIMG: (state, img) => {
    state.headerImg = img;
    sessionStorage.setItem("HEADERIMG", img)
  },
  // 设置accessToken
  SET_ACCESSTOKEN: (state, token) => {
    state.accessToken = token;
  },
  // 选中状态的menuId
  SET_MENUID: (state, id) => {
    state.menuId = id;
    sessionStorage.setItem("SET_MENUID_SESSION", JSON.stringify(id))
  },
  // 选中状态的menuId
  SET_MENUGROUP: (state, data) => {
    state.menuGroup= data;
    sessionStorage.setItem("SET_MENUID_SESSION_GROUP", JSON.stringify(data))
  },
  // 设置userName
  SET_USERNAME: (state, userName) => {
    state.userName = userName;
  },

  // 设置breadcrumbList面包屑
  SET_BREADCRUMB: (state, list) => {
    state.breadcrumbList = list.map(val => val);
  },

  // 设置当前用户可用的功能列表
  SET_FUNLIST: (state, list) => {
    state.funList = list.map(val => val);
  },

};

export default createStore({
  state,
  getters,
  actions,
  mutations,
  modules: {

  }
})
