<template>
  <div>
    <el-form style="width: 400px" :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="80px"
             label-position="top">
      <el-form-item label="姓名" prop="cnName">
        <el-input v-model="ruleForm.cnName"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="ruleForm.phone"></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="ruleForm.email"></el-input>
      </el-form-item>
      <el-form-item label="身份证号" prop="idCardNo">
        <el-input v-model="ruleForm.idCardNo"></el-input>
      </el-form-item>
      <el-form-item label="行政区划" prop="area">
        <el-cascader
            v-model='ruleForm.areaArr'
            placeholder="请选择"
            style='width: 100%;'
            :clearable='true'
            :options='cityData'
            :props='{label:"value"}'
            class='mr-2 noBorder'
        />
      </el-form-item>
      <el-form-item label="通讯地址" prop="addr">
        <el-input v-model="ruleForm.addr"></el-input>
      </el-form-item>
      <div class="form-reset-submit">
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button style="margin-right: 20px" @click="close">关闭</el-button>

      </div>
    </el-form>
  </div>
</template>
<script>
import {checkPhone, checkEmail} from '@/utils/common'
// import { updateUserInfo } from '@/api/UserInfo'
import actions from '../../../../action';
import getData from '@/assets/js/city.json'
import {updatePersonUserInfo} from "@/api/UserInfo";

export default {
  props: {
    userInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() { // 初始化数据
    return {
      ruleForm: {
        areaArr:[]
      },
      rules: {
        username: [
          {validator: this.validateUsername, trigger: 'blur'}
        ],
        phoneNum: [
          {validator: this.validatePhoneNum, trigger: 'blur'}
        ],
        email: [
          {validator: this.validateEmail, trigger: 'blur'}
        ]
      }
    }
  },
  watch: {
    userInfo(newV, oldV) {
      this.ruleForm = newV
      this.ruleForm.areaArr = this.ruleForm.area?this.ruleForm.area.split("-"):[]
    }
  },
  methods: { // 定义函数
    validateUsername(rule, value, callback) {
      if (value.length < 2 || value.length > 10) return callback(new Error('用户名称长度为2-10位'))
      return callback()
    },
    validatePhoneNum(rule, value, callback) {
      if (!checkPhone(value)) return callback(new Error('手机号格式不正确'))
      return callback()
    },
    validateEmail(rule, value, callback) {
      if (!checkEmail(value)) return callback(new Error('邮箱格式不正确'))
      return callback()
    },
    reset() {
      this.$refs.ruleForm.resetFields()
    },
    // 关闭个人中心
    close() {
      this.$refs.ruleForm.resetFields()
      let path = localStorage.getItem('userPath')
      this.$router.replace(path)
    },
    onSubmit() {
      this.$refs.ruleForm.validate((result, object) => {
        if (result) {
          this.$emit('uploadInfo', {loading: true})
          let params = {
            "addr": this.ruleForm.addr,
            "cnName": this.ruleForm.cnName,
            "email": this.ruleForm.email,
            "id": this.ruleForm.id,
            "idCardNo": this.ruleForm.idCardNo,
            "phone": this.ruleForm.phone,
            "photo": this.ruleForm.photo
          }
          params.area =this.ruleForm.areaArr?this.ruleForm.areaArr.join('-'):''
          updatePersonUserInfo(params).then(res => {
            if (res.code === 200) {
              this.reset()
              this.$message({
                type: 'success',
                message: '修改基本资料成功',
                duration: 1000,
              })

              this.$emit('uploadInfo', {loading: false})
            } else {
              this.$message({type: 'error', message: '修改基本资料失败'})
            }
          }, error => {
            this.$emit('uploadInfo', {loading: false})
          })
        }
      })
    }
  },
  components: { // 解构映射到组件

  },
  mounted() {
    this.cityData = getData
  }
}
</script>
<style scoped lang='less'>
.form-reset-submit {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-form-item__label {
  color: var(--text-color);
}

</style>
