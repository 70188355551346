<template>
  <div class="userInfo flex justify-between">
    <div class="left">
      <h5 class="pub_border_left mb-6"  style="line-height: 18px;"> 个人信息</h5>
      <div class="header flex f_c">
        <img :src='imageUrl' v-if="imageUrl" class='avatar'>
        <img src='../../assets/img/User/header.png' v-if="!imageUrl" class='avatar'>
        <el-upload ref='uploadRef' :file-list='fileList' class='avatar-uploader' :action='uploadFileUrl'
          :headers='headers' :on-change='handleChange' :before-upload='beforeAvatarUpload'
          :on-success='handleAvatarSuccess' :show-file-list='false' accept='image/*'>
          <el-button type="primary">上传头像</el-button>
        </el-upload>
      </div>
      <div class=" mt-20 ml-3">
        <div v-for="(item,index) in userInfo" class="mb-2 ">
          <span class="icon " :class="item.keyIcon"></span>
          <span>{{ details[item.key] ||'--'}}</span>

        </div>
      </div>

    </div>
    <div class="right">
      <h5 class="pub_border_left" style="line-height: 18px;"> 基本信息</h5>
      <editPersonalInfo ref="editPersonalInfo" :userInfo="details" @uploadInfo="getInfo"></editPersonalInfo>
    </div>
  </div>
</template>

<script>
  import editPersonalInfo from './editPersonalInfo'
  import { getUserInfo, updatePersonUserInfo, getUserInfoByToken } from "@/api/UserInfo"
  import { mapMutations, mapState } from "vuex";
  export default {
    name: "userInfo",
    data() {
      return {
        imageUrl: '',
        fileList: [],
        uploadFileUrl: '/fire/api/app/upload',
        headers: { Authorization: JSON.parse(localStorage.getItem('token')) },
        details: {},
        userInfo: [
          {
            keyIcon: 'user',
            key: 'cnName'
          },
          {
            keyIcon: 'phone',
            key: 'phone'
          },
          {
            keyIcon: 'email',
            key: 'email'
          },
          {
            keyIcon: 'id',
            key: 'idCardNo'
          },
          {
            keyIcon: 'sheng',
            key: 'area'
          }, {
            keyIcon: 'address',
            key: 'addr'
          }
        ],


      }
    },
    components: {
      editPersonalInfo,
    },
    methods: {
      ...mapMutations(['SET_HEADERIMG']),
      handleChange(uploadFile, uploadFiles) {
        this.fileList = [uploadFile]
      },
      beforeAvatarUpload() {
      },
      handleAvatarSuccess(res) {
        this.imageUrl = res.tempUrl
        let params = {
          "id": this.details.id,
          "photo": res.url,
          "addr": this.details.addr,
          "area": this.details.area,
          "cnName": this.details.cnName,
          "email": this.details.email,
          "idCardNo": this.details.idCardNo,
          "phone": this.details.phone,
        }
        updatePersonUserInfo(params).then(res => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '修改基本资料成功',
              duration: 1000,
            })
            this.SET_HEADERIMG(this.imageUrl)
            // 刷新个人中心
            this.$nextTick(() => {
              this.getInfo()
            })
          } else {
            this.$message({ type: 'error', message: '上传失败' })
          }
        }, error => {
        })
      },
      getInfo() {
        // 根据 根据token获取用户名
        getUserInfoByToken().then(r => {
          if (r.code === 200) {
            getUserInfo({ username: r.data.userName }).then(res => {
              let arr = []
              let arrName = []
              this.details = res.data.sysUser
              this.details.areaArr =(res.data.sysUser.area)?(res.data.sysUser.area).split('-'):[]
              this.imageUrl = this.details.photoTemp ?  this.details.photoTemp : ''
            })
          }
        })
      },
      uploadInfo() {

      },
    },
    mounted() {
      this.getInfo()
    }
  }
</script>

<style scoped lang="less">
.f_c{
align-items: center;
}
  .userInfo {
  position: absolute;
    top:84px;
    width: calc(100vw - 296px);
    .pub_border_left {
      border-left: 2px solid var(--el-color-primary);
      padding-left: 10px;
    }


    h5 {
      font-size: 18px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }

    .left {
      width: 400px;
      height: calc(100vh - 100px);
      background: #E6F3FF;
      padding: 20px;
      box-shadow: 0px 2px 6px 1px rgba(3, 75, 143, 0.1608);

      .header {
        flex-direction: column;

        .avatar {
          height: 144px;
          width: 144px;
          margin-bottom: 15px;
          border-radius: 50%;
        }
      }

      .icon {
        display: inline-block;
        height: 14.5px;
        width: 28px;

      }

      .user {
        background: url(../../assets/img/user.png) left no-repeat;
        background-size: contain;

      }

      .phone {
        background: url(../../assets/img/phone.png) left no-repeat;
        background-size: contain;
      }

      .email {
        background: url(../../assets/img/email.png) left no-repeat;
        background-size: contain;
      }

      .id {
        background: url(../../assets/img/id.png) left no-repeat;
        background-size: contain;
      }

      .sheng {
        background: url(../../assets/img/sheng.png) left no-repeat;
        background-size: contain;
      }

      .address {
        background: url(../../assets/img/address.png) left no-repeat;
        background-size: contain;
      }
    }

    .right {
      width: calc(100% - 420px);
      height: calc(100vh - 100px);
      padding: 20px;
      box-shadow: 0px 2px 6px 1px rgba(3, 75, 143, 0.1608);
    }
  }
</style>