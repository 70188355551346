<template>
  <div class='register  flex flex-center'>
    <div class='register_content'>
      <div class="title">找回密码</div>
      <div class='steps-box'>
        <el-steps :active='active' finish-status='success'>
          <el-step>
            <template #icon>
              <div class="stepIcon" :class="active==0?'active':''">
                账号
              </div>
            </template>
          </el-step>
          <el-step title=''>
            <template #icon>
              <div class="stepIcon" :class="active==1?'active':''">
                验证
              </div>
            </template>
          </el-step>
          <el-step title=''>
            <template #icon>
              <div class="stepIcon" :class="active==2?'active':''">
                重置
              </div>
            </template>
          </el-step>
        </el-steps>
      </div>
      <div class='register-type'>
        <div class='form-box'>
          <el-form ref='ruleForm' :model='ruleForm' :rules='rules' label-position='right' class='demo-ruleForm' @submit.native.prevent>
            <el-form-item prop='username' style="height: 50px;" class='itemForm flex' v-if="active==0">
              <el-input v-model='ruleForm.username' class="userinp" placeholder="请输入账号" autocomplete='off'
                        maxlength='11'>
                <template #prefix>
                  <div class="prefixUser"></div>
                  <div class="prefixUserRight"></div>
                </template>
              </el-input>
            </el-form-item>

            <el-form-item prop='phone' style="height: 50px;" class='itemForm flex' v-if="active==1">
              <el-input v-model='ruleForm.phone' class="userinp" placeholder="请输入手机号" autocomplete='off' maxlength='11'>
                <template #prefix>
                  <div class="prefixUser"></div>
                  <div class="prefixUserRight"></div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop='code' style="height: 50px;" class='itemForm flex code' v-if="active==1">
              <el-input v-model='ruleForm.code' placeholder="验证码" autocomplete='off'>
                <template #prefix>
                  <div class="prefixUser"></div>
                  <div class="prefixUserRight"></div>
                </template>
              </el-input>
              <el-button type="text" class="getCode" @click="getCodeFun">{{ btnText }}</el-button>
            </el-form-item>
            <el-form-item prop='password' style="height: 50px;" class='itemForm flex' v-if="active==2">
              <el-input v-model='ruleForm.password' type='password' show-password class="userinp" placeholder="新密码">
                <template #prefix>
                  <div class="prefixUser"></div>
                  <div class="prefixUserRight"></div>
                </template>
              </el-input>
            </el-form-item>

            <el-form-item prop='confirmPwd' style="height: 50px;" class='itemForm flex ' v-if="active==2">
              <el-input
                  v-model='ruleForm.confirmPwd'
                  placeholder="确认新密码"
                  type='password'
                  show-password
              >
                <template #prefix>
                  <div class="prefixPass"></div>
                  <div class="prefixUserRight"></div>
                </template>
              </el-input>
            </el-form-item>

          </el-form>
          <div>
            <!--            <el-button type='primary' @click='next'>返回</el-button>-->
            <el-button type='primary' class="btn cursor-pointer" @click='next' v-if="active==0">下一步</el-button>
            <div class="flex">
              <p type="text" class="btn_p cursor-pointer" style="width: 100px" @click='goback'
                 v-if="active==1||active==2">返回</p>
              <el-button type='primary' style="width: calc(100% - 100px);" class="btn" @click='next'
                         v-if="active==1||active==2">{{ active == 1 ? '下一步' : '完成' }}
              </el-button>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--        <el-dialog-->
    <!--            v-model:visible='dialogVisible'-->
    <!--            title='平台相关协议'-->
    <!--            width='60%'-->
    <!--            center-->
    <!--            :show-close='false'-->
    <!--            :close-on-click-modal='false'-->
    <!--        >-->
    <!--            <div class='agreement'>-->
    <!--                <iframe-->
    <!--                    id='iframename'-->
    <!--                    src='https://cf-user.chengfengkuaiyun.com/cf-register.html'-->
    <!--                    width='100%'-->
    <!--                    height='800'-->
    <!--                    frameborder='0'-->
    <!--                    scrolling='auto'-->
    <!--                    name='iframename'-->
    <!--                />-->
    <!--            </div>-->
    <!--            <span slot='footer' class='dialog-footer'>-->
    <!--                <el-button type='danger' @click='closeMask'>取 消</el-button>-->
    <!--                <el-button type='success' @click='getNum'>{{ sendShort }}</el-button>-->
    <!--            </span>-->
    <!--        </el-dialog>-->
  </div>
</template>

<script>
import {changePassword, changePasswordFort, checkUsername, getCode, verifyMessageCode} from "@/api/UserInfo";
import {ElMessage} from 'element-plus'
import JSEncrypt from "jsencrypt";

const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCk2nvbv1V92I7wodOVGhAezEHrRHsPj3XQRzLEUYzhkXSqTAVDJrC3fS8c8bz2NDIROr5XzOmgTVrDIFL/kYSjc79Q+65684pYMN2R+pZKplB8hlUhJ5LscrES43TquLM6Oycp98D64tqtGm/XIURoi4b9iDQpVFNFqk3O1K919wIDAQAB';
const encrypt = new JSEncrypt();
encrypt.setPublicKey(publicKey);
export default {
  components: {},
  data() {
    return {
      sendShort: '',
      totalTime: '10',
      canClick: true, // 控制是否可以点击
      dialogVisible: false,
      active: 0,
      btnText: "获取验证码",
      time: 60,
      ruleForm: {
        phone: '',
        verification: '',
        pass: '',
        checkPass: ''
      },
      rules: {
        username: [
          {pattern: /^[a-zA-Z0-9_]+$/, message: '请输入由英文字母、下划线、数字构成的用户名', trigger: ['change','blur']}
        ],
        phone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {
            pattern: /^1[3-9]\d{9}$/,
            message: '手机号格式不正确',
            trigger: ['change','blur']
          }
        ],
        verification: [
          {required: true, message: '请输入验证码', trigger: ['change','blur']},
          {min: 6, max: 6, message: '请输入正确的验证码', trigger: ['change','blur']}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: ['change','blur']},
          {
            pattern: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,.\/]).{8,24}$/,
            message: '密码包含字母数字特殊字符长度8到24位',
            trigger: ['blur', 'change']
          },
        ],

      }
    }
  },
  created() {
  },
  methods: {
    next() {
      if (this.active === 0) {
        if (!this.ruleForm.username) {
          ElMessage.error("用户名不能为空")
          return
        }
        this.$refs.ruleForm.validateField('username',errorMsg=>{
          if(errorMsg){
            // 验收用户名
            checkUsername({username: encrypt.encrypt(this.ruleForm.username)}).then(res => {
              if (res.code === 200) {
                this.active += 1
              } else {
                ElMessage.error("用户名错误请重新输入")
              }
            })
          }
        })

      } else if (this.active === 1) {
        if (!this.ruleForm.username) {
          ElMessage.error("用户名不能为空")
          return
        }
        if (!this.ruleForm.code) {
          ElMessage.error("验证码不能为空")
          return
        }
        // 验收手机号
        verifyMessageCode({username: encrypt.encrypt(this.ruleForm.username), code: this.ruleForm.code}).then(res => {
          if (res.code === 200) {
            this.active += 1
          } else {
            ElMessage.error("验证码错误")
          }
        })
      } else {
        let params = {
          "confirmPwd": encrypt.encrypt(this.ruleForm.confirmPwd),
          "password": encrypt.encrypt(this.ruleForm.password),
          "username": encrypt.encrypt(this.ruleForm.username)
        }
        changePasswordFort(params).then(res => {
          if (res.code === 200) {
            ElMessage.success("操作成功")
            this.$router.push("/")

          }
        })
      }
    },
    // 返回
    goback() {
      this.active -= 1
      // this.ruleForm
    },
    goLogin() {
      this.$router.push('/login')
    },
    closeMask() {
      this.dialogVisible = false
      // window.clearInterval(this.cloak);
      // this.totalTime='10';
    },

    //获取手机验证码方法
    getCodeFun() {
      // 校验手机号码
      if (!this.ruleForm.phone) {
        //号码校验不通过
        this.$message({
          message: '手机号不能为空',
          type: 'warning',
        });
        //正则判断 从1开始，第二位是35789中的任意一位，以9数字结尾
      } else if (!/1[35789]\d{9}/.test(this.ruleForm.phone)) {
        // 失去焦点后自动触发校验手机号规则
        this.$message({
          message: '手机号格式不正确',
          type: 'warning',
        });
      } else {
        let params = {
          "phone": this.ruleForm.phone,
          "resetType": 0,
          "username": encrypt.encrypt(this.ruleForm.username)
        }
        getCode(params).then(res => {
          if (res.code === 200) {
            this.time = 60;
            this.disabled = true;
            //调用倒计时方法
            this.timer();
          }
        }).catch(err => {
          this.btnText = "获取验证码";
          this.disabled = false;
        })

      }
    },
    timer() {
      if (this.time > 0) {
        this.time--;
        this.btnText = this.time + "s后重新获取验证码";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btnText = "获取验证码";
        this.disabled = false;
      }
    },
  }
}
</script>

<style lang="less" scoped>
.register {
  height: 100vh;
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/img/User/register.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;

  .register_content {
    width: 800px;
    height: 600px;
    background: #FFFFFF;
    box-shadow: 0px 0px 18px 2px rgba(64, 147, 250, 0.1);
    border-radius: 4px;

    .title {
      text-align: center;
      position: relative;
      line-height: 50px;
      margin: 80px auto;
      font-size: 34px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #000000;
    }

    .title::after {
      content: ' ';
      position: absolute;
      height: 2px;
      width: 64px;
      bottom: 0;
      background: #1890FF;
      left: 50%;
      transform: translate(-50%, 0);
    }

    .steps-box {
      width: 40%;
      margin: 0 auto;

      .stepIcon {
        width: 40px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 20px;
        text-align: center;
        line-height: 40px;
        border: 1px solid #919191;
        color: #919191;
      }

      .active {
        border: 1px solid #3381FF;
        color: #3381FF;
      }

      ::v-deep(.el-step__line-inner) {
        display: none;
      }

      ::v-deep(.el-step__line) {
        height: 1px;
        position: absolute;
        border-color: inherit;
        background-color: transparent;
        border: 1px dashed var(--el-text-color-placeholder);
      }

      .el-step.is-simple:not(:last-of-type) .el-step__title {
        max-width: none;
      }
    }
  }

  .itemForm {
    height: 50px;
    box-sizing: border-box;
    margin: 0 auto 40px;
    border: 1px solid #DCDCDC;
    border-radius: 4px;

    .el-input {
      width: 100%;
      height: 100%;
    }

    ::v-deep(.el-input__inner) {
      width: 100%;
      height: 100%;
      border: none !important;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 300;
      color: #333333 !important;
    }

    ::v-deep(.el-input__wrapper) {
      box-shadow: none;
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  .code {
    position: relative;
  }

  .getCode {
    position: absolute;
    right: 30px;
  }

  .btn_p {
    position: relative;
    font-size: 14px;
  }

  .btn_p::after {
    content: ' ';
    position: absolute;
    height: 2px;
    width: 30px;
    bottom: 0;
    background: #333333;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .btn {
    width: 100%;
    height: 44px;
    background: #1890FF;
    border-radius: 4px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 33px;
    margin: 0 auto 0;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .prefixUser {
    width: 22px;
    height: 22px;
    margin-right: 10px;
    background-image: url('../../assets/img/User/username.png');
    background-repeat: no-repeat;
  }

  .prefixPass {
    width: 22px;
    height: 22px;
    margin-right: 10px;
    background-image: url('../../assets/img/User/password.png');
    background-repeat: no-repeat;
  }

  .prefixUserRight {
    width: 1px;
    height: 34px;
    background: #DCDCDC;
    margin-right: 16px;
  }

  .register-title {
    width: 1000px;
    height: 98px;
    background: #ffffff;
    line-height: 98px;
    margin: 0 auto;
    display: flex;

    .logo-item {
      width: 137px;
      height: 34px;
      //background: url("./images/zhucetitle.png") no-repeat;
      background-size: 100% 100%;
      margin-top: 32px;
    }

    .world-item {
      width: 96px;
      height: 33px;
      line-height: 33px;
      text-align: center;
      margin-top: 33px;
      margin-left: 20px;
      color: #05B37B;
      border-left: 2px solid #E9E9E9;
    }

    .login-item {
      margin-left: 615px;
      font-size: 14px;
      color: #555555;

      em {
        color: #05B37B;
        cursor: pointer;
      }
    }
  }

  .register-image {
    height: 182px;
    line-height: 182px;
    text-align: center;
    font-size: 40px;
    color: #ffffff;
    width: 100%;
    /*background: #00CB8A;*/
    //background: url('./images/navbar.png') no-repeat;
    background-size: 100% 100%;
  }

  .register-type {
    width: 50%;
    margin: 0 auto;
    margin-top: 50px;
    /*display: flex;*/
    /*flex-direction: row;*/
    /*justify-content: space-between;*/

    img {
      display: block;
      width: 148px;
      height: 124px;
      /*border: 1px solid #33aef0;*/
      margin: 0 auto;
    }

    p {
      width: 100%;
      text-align: center;
      font-size: 18px;
      color: #333333;
      margin-top: 14px;
    }

    .agent {
      width: 290px;
      height: 312px;

      .el-button {
        width: 290px;
        height: 70px;
        background: #00cb8a;
      }

      .el-button--primary {
        border-color: transparent;
      }

      .agent-img {
        padding-top: 40px;
        width: 100%;
        height: 242px;
        background: white;
      }
    }

    .shipper:hover {
      transform: translate(0, -20px);
      transition: 1.5s;
      -webkit-transform: translate(0, -20px);
      -moz-transform: translate(0, -20px);
      -o-transform: translate(0, -20px);
      -ms-transform: translate(0, -20px);
    }

    .agent:hover {
      transform: translate(0, -20px);
      transition: 1.5s;
      -webkit-transform: translate(0, -20px);
      -moz-transform: translate(0, -20px);
      -o-transform: translate(0, -20px);
      -ms-transform: translate(0, -20px);
    }
  }

  .register-container {
    padding: 30px;
    width: 1000px;
    height: 510px;
    margin: 0 auto;


    .next-box {
      width: 400px;
      bottom: 20px;
      margin: 0 auto;

      .el-button {
        width: 100%;
        background: #00cb8a;
      }

      .el-button--primary {
        border-color: transparent;
      }
    }
  }

  .agreement {
    user-select: none;
    width: 100%;
    height: 500px;
    overflow: auto;
  }
}
</style>
