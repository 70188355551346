<template>
  <div class="about-content flex ">
    <div v-for="item in tenantList">
      <el-card class="box-card card flex" @click="chooseApp(item)" v-if="item.appType!=2">
        <div class="flex column f_c_c mr-4">

          <img v-if='item.pathTemp' :src='item.pathTemp' alt=''>
          <img v-if='!item.pathTemp' :src='baseImg' alt='' class=''>
          <el-tag class="mt-2">{{ item.appType == 2 ? "小程序" : "PC" }}</el-tag>
        </div>

        <div class="flex_list">
          <p class='name' :title="item.cnName">{{ item.cnName }}</p>
          <p class='time'>{{ item.expireState }}</p>
          <p class='des'>{{ item.descr }}</p>
        </div>
      </el-card>
      <el-card class="box-card card flex xcx" v-if="item.appType==2">
        <div class="flex column f_c_c mr-4">
          <img v-if='item.pathTemp' :src='item.pathTemp'  alt='' @click="close">
          <img v-if='!item.pathTemp' :src='baseImg' alt=''  @click="close">
          <el-tag class="mt-2">{{ item.appType == 2 ? "小程序" : "PC" }}</el-tag>
        </div>
        <div class="flex_list" v-if="!item.show" @click="chooseApp(1,item)">
          <p class='name'>{{ item.cnName }}</p>
          <p class='time'>{{ item.expireState }}</p>
          <p class='des'>{{ item.descr }}</p>
        </div>
        <transition name='fade-transform' mode='out-in'>
          <div class="flex_list ccc" id="qr-code" v-if="item.show">
            <img :src='item.previewPathTemp||baseImg' alt='' class='mr-4 overlay'>
          </div>
        </transition>
      </el-card>
    </div>
  </div>
</template>

<script setup>
import {defineEmits, defineProps, ref} from "vue";
import baseImg from '@/assets/img/APPC.png'
import {ElMessage} from "element-plus";

const apiBase = process.env.VUE_APP_URL_IMG
const props = defineProps({
  // 子组件接收父组件传递过来的值
  tenantList: Array,
})

const emit = defineEmits(['isApp', 'closeApp'])
const show = ref(true)
const close = () => {
  emit('closeApp')
}
const chooseApp = (row, item) => {
  // console.log(row.cnName)
  document.title = row.cnName || '电力交易' // 设置页面标题
  // 判断是不是小程序
  if (row === 1) {
    item.show = true
    return;
  }

  // 判断app是不是已经过期了
  if (row.expireDate) {
    let dataTime = new Date(row.expireDate).getTime()
    let nowTime = new Date().getTime()
    if (nowTime > dataTime) {
      ElMessage.warning('应用已过期,请联系管理员处理')
    } else {
      emit('isApp', row)
    }
    return
  }
  emit('isApp', row)
}
const getTime = (time) => {
  if (time) {
    return time
  } else {
    return '长期有效'
  }
}
// 主动暴露childMethod方法
defineExpose({chooseApp})
</script>
<style>
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0s;
}

.fade-transform-enter-from {
  opacity: 0;
//transform: translateY(30deg);
}

.fade-transform-leave-to {
  opacity: 0;
//transform: translateY(-30deg);
}

</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="postcss" scoped>
::v-deep(.el-card__body) {
  padding: 0;
}

.column {
  flex-direction: column;
}

.ccc.flex_list {
  position: relative;
  width: 100%;
  height: 144px;
  background-color: #ccc;
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: auto;
}

.box-card {
  width: 300px;
  height: 150px;
  font-size: 30px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-right: 40px;
  margin-bottom: 20px;


}

.flex_list {
  flex: 1;
  width: calc(100% - 70px);

  .name {
    overflow: hidden;
    text-overflow: ellipsis; /* 加省略号 */
    white-space: nowrap; /* 强制不换行 */

  }
}

.box-card:hover {
  border: 1px solid #1890FF;
}

.about-content {
  padding: 20px;
  cursor: pointer;
  flex-wrap: wrap;
}

.f_20 {
  font-size: 22px;
  text-align: center;
  font-weight: bold;
}

.f_16 {
  font-size: 18px;
  text-align: center;
  color: #C1C1C1;
  margin-top: 10px;
}

.name {
  font-size: 18px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}

.time {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #F6A948;
  line-height: 30px;
}

.des {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #B3B3B3;
}

img {
  display: inline-block;
  height: 50px;
  width: 50px;
  overflow: hidden;
}

.card {
  width: 300px;
  height: 144px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1608);
  border-radius: 10px 10px 10px 10px;


}

::v-deep(.el-card__body) {
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 20px;
  height: 100%;
}


</style>
