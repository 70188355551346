<template>
  <div class='register  flex flex-center'>
    <div class='register_content'>
      <div class="title">用户注册</div>
      <div class='steps-box'>
        <el-steps :active='active' finish-status='success'>
          <el-step>
            <template #icon>
              <div class="stepIcon" :class="active==0?'active':''">
                账号
              </div>
            </template>
          </el-step>
          <el-step title=''>
            <template #icon>
              <div class="stepIcon" :class="active==1?'active':''">
                验证
              </div>
            </template>
          </el-step>
        </el-steps>
      </div>
      <div class='register-type'>
        <div class='form-box'>
          <el-form ref='ruleForm' :model='ruleForm' :rules='rules' label-position='right' class='demo-ruleForm'>
            <el-form-item prop='username' style="height: 50px;" class='itemForm flex' v-if="active==0">
              <el-input v-model='ruleForm.username' class="userinp" placeholder="请输入账号" autocomplete='off'
                        maxlength='11'>
                <template #prefix>
                  <div class="prefixUser"></div>
                  <div class="prefixUserRight"></div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop='password' style="height: 50px;" class='itemForm flex ' v-if="active==0">
              <el-input
                  v-model='ruleForm.password'
                  placeholder="登录密码"
                  show-password
              >
                <template #prefix>
                  <div class="prefixPass"></div>
                  <div class="prefixUserRight"></div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop='phone' style="height: 50px;" class='itemForm flex' v-if="active==1">
              <el-input v-model='ruleForm.phone' class="userinp" placeholder="请输入手机号" autocomplete='off'
                        maxlength='11'>
                <template #prefix>
                  <div class="prefixUser"></div>
                  <div class="prefixUserRight"></div>
                </template>
              </el-input>
            </el-form-item>


            <el-form-item prop='code' style="height: 50px;" class='itemForm flex code' v-if="active==1">
              <el-input v-model='ruleForm.code' placeholder="验证码" autocomplete='off' maxlength='11'>
                <template #prefix>
                  <div class="prefixMess"></div>
                  <div class="prefixUserRight"></div>
                </template>
              </el-input>
              <el-button type="text" class="getCode" @click="getCode">{{ btnText }}</el-button>
            </el-form-item>
          </el-form>
          <div>
            <!--            <el-button type='primary' @click='next'>返回</el-button>-->
            <el-button type='primary' class="btn cursor-pointer" @click='next' v-if="active==0">下一步</el-button>
            <div class="flex">
              <p type="text" class="btn_p cursor-pointer" style="width: 100px" @click='goback' v-if="active==1">返回</p>
              <el-button type='primary' style="width: calc(100% - 100px);" class="btn" @click='next' v-if="active==1">
                完成
              </el-button>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- 补充企业资料 -->
<!--    <div class="register_supplement" v-if ="active===2 ||active===3">-->
<!--      <div class="r_s_box p20">-->
<!--        <p class="titleShow">{{active===2?"补充企业资料": "请选择您想使用的应用" }}</p>-->
<!--        <div class='flex flex-center' v-if=" active===2">-->
<!--          <el-form-->
<!--              ref='searchForm'-->
<!--              style='width: 90%'-->
<!--              :rules='rules'-->
<!--              :model='formInline'-->
<!--              class='mt-4'-->
<!--              label-width='150'-->
<!--          >-->
<!--            <el-row :gutter='50'>-->
<!--              <el-col :span='12'>-->

<!--                <el-form-item label='组织名称：' prop='cnName'>-->
<!--                  <el-input-->
<!--                      v-model='formInline.cnName'-->
<!--                      maxlength='60'-->
<!--                      show-word-limit-->
<!--                      placeholder='请输入'-->
<!--                  />-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--              <el-col :span='12'>-->

<!--                <el-form-item label='组织简称：' prop='sname'>-->
<!--                  <el-input-->
<!--                      v-model='formInline.sname'-->
<!--                      maxlength='20'-->
<!--                      show-word-limit-->
<!--                      placeholder='请输入'-->
<!--                  />-->
<!--                </el-form-item>-->

<!--              </el-col>-->
<!--              <el-col :span='12'>-->
<!--                <el-form-item label='英文缩写：' prop='senName'>-->
<!--                  <el-input-->
<!--                      v-model='formInline.senName'-->
<!--                      maxlength='10'-->
<!--                      show-word-limit-->
<!--                      placeholder='请输入'-->
<!--                  />-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--              <el-col :span='12'>-->
<!--                <el-form-item label='统一社会信用代码：' prop='orgCode'>-->
<!--                  <el-input-->
<!--                      v-model='formInline.orgCode'-->
<!--                      placeholder='请输入'-->
<!--                  />-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--              <el-col :span='12'>-->
<!--                <el-form-item label='行政区划：' prop='divisionDivision'>-->
<!--                  <el-cascader-->
<!--                      v-model='formInline.areaArr'-->
<!--                      style='width: 100%;'-->
<!--                      :options='cityData'-->
<!--                      :props='{label:"value"}'-->
<!--                      class='mr-2 noBorder'-->
<!--                  />-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--              <el-col :span='12'>-->
<!--                <el-form-item label='通信地址：' prop='addr'>-->
<!--                  <el-input-->
<!--                      v-model='formInline.addr'-->
<!--                      type='textarea'-->
<!--                      maxlength='100'-->
<!--                      show-word-limit-->
<!--                      :rows='4'-->
<!--                      placeholder='请输入'-->
<!--                  />-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--              <el-col :span='12'>-->
<!--                <el-form-item label='备注：' prop='describe'>-->
<!--                  <el-input-->
<!--                      v-model='formInline.describe'-->
<!--                      type='textarea'-->
<!--                      maxlength='200'-->
<!--                      show-word-limit-->
<!--                      :rows='4'-->
<!--                      placeholder='请输入'-->
<!--                  />-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--            </el-row>-->
<!--            <div class='register-type1 flex f_c_e'>-->
<!--              &lt;!&ndash;              <div class='form-box'>&ndash;&gt;-->
<!--              <div class="flex f_c_e">-->
<!--                <p type="text" class="btn_p cursor-pointer" style="width: 100px" @click='goback'>取消</p>-->
<!--                <el-button type='primary' style="width: calc(100% - 100px);" class="btn" @click='next'>-->
<!--                  下一步,选择应用-->
<!--                </el-button>-->
<!--              </div>-->
<!--              &lt;!&ndash;              </div>&ndash;&gt;-->
<!--            </div>-->
<!--          </el-form>-->
<!--        </div>-->
<!--        <div class='p20' v-if=" active===3">-->
<!--          <el-form-->
<!--              ref='searchForm'-->
<!--              style='width: 90%'-->
<!--              :rules='rules'-->
<!--              :model='formInline'-->
<!--              class='mt-4'-->
<!--              label-width='120'-->
<!--          >-->
<!--            <el-row>-->
<!--              <el-col :span='12'>-->
<!--                <el-form-item label='选择企业类型：' prop='divisionDivision'>-->
<!--                  <el-select-->
<!--                      v-model='formInline.a'-->
<!--                      style='width:300px;'-->
<!--                      placeholder="选择企业类型"-->
<!--                      class='mr-2 noBorder'-->
<!--                  />-->
<!--                </el-form-item>-->
<!--              </el-col>-->

<!--            </el-row>-->

<!--          </el-form>-->
<!--          <el-checkbox-group v-model="checkList">-->
<!--            <div class="cardBox flex">-->
<!--              <div v-for="(item,index) in tenantList" class="w50">-->
<!--                <div class="box-card card flex" @click="chooseApp(index)">-->
<!--                  <el-checkbox :label="index" size="large" class="check-box-btn"/>-->
<!--                  <div class="flex column f_c_c mr-4">-->
<!--                    <img v-if='item.pathTemp' :src='item.pathTemp' alt=''>-->
<!--                    <img v-if='!item.pathTemp' :src='baseImg' alt='' class=''>-->
<!--                  </div>-->
<!--                  <div class="flex_list flex column ">-->
<!--                    <p class='name' :title="item.cnName">{{ item.cnName }}</p>-->
<!--                    <p class='p2'>{{ item.descr }}</p>-->
<!--                  </div>-->
<!--                </div>-->

<!--              </div>-->
<!--            </div>-->
<!--          </el-checkbox-group>-->
<!--          <div class='register-type1 flex f_c_e'>-->
<!--            <div class="flex f_c_e">-->
<!--              <p type="text" class="btn_p cursor-pointer" style="width: 100px" @click='active===2'>上一步</p>-->
<!--              <el-button type='primary' style="width: calc(100% - 100px);" class="btn" @click='next'>-->
<!--                完成-->
<!--              </el-button>-->
<!--            </div>-->
<!--            &lt;!&ndash;              </div>&ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <!--        <el-dialog-->
    <!--            v-model:visible='dialogVisible'-->
    <!--            title='平台相关协议'-->
    <!--            width='60%'-->
    <!--            center-->
    <!--            :show-close='false'-->
    <!--            :close-on-click-modal='false'-->
    <!--        >-->
    <!--            <div class='agreement'>-->
    <!--                <iframe-->
    <!--                    id='iframename'-->
    <!--                    src='https://cf-user.chengfengkuaiyun.com/cf-register.html'-->
    <!--                    width='100%'-->
    <!--                    height='800'-->
    <!--                    frameborder='0'-->
    <!--                    scrolling='auto'-->
    <!--                    name='iframename'-->
    <!--                />-->
    <!--            </div>-->
    <!--            <span slot='footer' class='dialog-footer'>-->
    <!--                <el-button type='danger' @click='closeMask'>取 消</el-button>-->
    <!--                <el-button type='success' @click='getNum'>{{ sendShort }}</el-button>-->
    <!--            </span>-->
    <!--        </el-dialog>-->
  </div>
</template>

<script>
import {checkUsername, getCodeApi, isUserExist, register, registerOutPre, registerOut} from "@/api/UserInfo";
import JSEncrypt from "jsencrypt";
import {ElMessage} from "element-plus";
import getData from '@/assets/js/city1.json'
const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCk2nvbv1V92I7wodOVGhAezEHrRHsPj3XQRzLEUYzhkXSqTAVDJrC3fS8c8bz2NDIROr5XzOmgTVrDIFL/kYSjc79Q+65684pYMN2R+pZKplB8hlUhJ5LscrES43TquLM6Oycp98D64tqtGm/XIURoi4b9iDQpVFNFqk3O1K919wIDAQAB';
const encrypt = new JSEncrypt();
encrypt.setPublicKey(publicKey);
import baseImg from '@/assets/img/APPC.png'

export default {
  components: {},
  data() {
    return {
      cityData:getData,
      baseImg,
      formInline: {},
      checkList: [],
      tenantList: [
        {
          cnName: 'hahhahahh ',
          expireState: "1231231232",
          descr: '使用的额题啊见是你是的粉红色短裤粉红色会计收到回复看手机话费卡是几点话费快捷方式肯定发生的纠纷哈地方开始的减肥后开始绝代风华就快点放寒假史蒂芬霍金是倒海翻江法就哈萨克',
          pathTemp: ''
        }, {
          cnName: '12312',
          expireState: "1231231232",
          descr: '12312',
          pathTemp: ''
        }, {
          cnName: '12312',
          expireState: "1231231232",
          descr: '12312',
          pathTemp: ''
        }
      ],
      sendShort: '',
      totalTime: '10',
      canClick: true, // 控制是否可以点击
      dialogVisible: false,
      active: 0,
      btnText: "获取验证码",
      time: 60,
      ruleForm: {
        username: '',
        password: '',
        phone: '',
        sms: ''
      },
      rules: {
        username: [
          {pattern: /^[a-zA-Z0-9_]+$/, message: '请输入由英文字母、下划线、数字构成的用户名', trigger: ['change', 'blur']}
        ],
        phone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {
            pattern: /^1[3-9]\d{9}$/,
            message: '手机号格式不正确',
            trigger: 'change'
          }
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {
            pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+.])[A-Za-z\d!@#$%^&*()_+.]{8,24}$/,
            message: '密码包含字母数字特殊字符长度8到24位',
            trigger: ['change', 'blur']
          },
        ],

      }
    }
  },
  created() {
  },
  methods: {
    chooseApp(index) {
      if (this.checkList.includes(index)) {
        this.checkList = this.checkList.filter(item => {
          return item !== index
        })
      } else {
        this.checkList.push(index)
      }
    },
    next() {

      // return;
      if (this.active === 0) {
        if (!this.ruleForm.username) {
          ElMessage.error("用户名不能为空")
          return
        }
        if (!this.ruleForm.password) {
          ElMessage.error("密码不能为空")
          return
        }
        if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+.])[A-Za-z\d!@#$%^&*()_+.]{8,24}$/.test(this.ruleForm.password)) {
          ElMessage.error("密码格式不正确")
          return
        }
        // 验证用户名是否重复
        isUserExist({username: encrypt.encrypt(this.ruleForm.username)}).then(res => {
          if (res.code === 200) {
            this.active += 1
          } else {
            ElMessage.error("请重新输入")
          }
        })
      } else {
        if (!this.ruleForm.code) {
          ElMessage.error("验证码不能为空")
          return
        }
        let params = {
          "code": this.ruleForm.code,
          "password": encrypt.encrypt(this.ruleForm.password),
          "phone": this.ruleForm.phone,
          "username": encrypt.encrypt(this.ruleForm.username),
          registerSource:"平台注册",
        }
        registerOut(params).then(res => {
          sessionStorage.setItem('loginUserName',this.ruleForm.username)
          ElMessage.success("注册成功")
          /* 调准到补充资料的页面中*/
          this.$router.push("/additionInfo")
          this.$emit("additionalInfo")
        })
      }
    },
    // 返回
    goback() {
      this.active = 0
      // this.ruleForm
    },
    goLogin() {
      this.$router.push('/login')
    },
    closeMask() {
      this.dialogVisible = false
      // window.clearInterval(this.cloak);
      // this.totalTime='10';
    },

    //获取手机验证码方法
    getCode() {
      // 校验手机号码
      if (!this.ruleForm.phone) {
        //号码校验不通过
        this.$message({
          message: '请输入手机号',
          type: 'warning',
        });
        //正则判断 从1开始，第二位是35789中的任意一位，以9数字结尾
      } else if (!/1[35789]\d{9}/.test(this.ruleForm.phone)) {
        // 失去焦点后自动触发校验手机号规则
        this.$message({
          message: '手机号格式不正确',
          type: 'warning',
        });
      } else {
        let params = {
          "phone": this.ruleForm.phone,
        }
        registerOutPre(params).then(res => {
          this.time = 60;
          this.disabled = true;
          //调用倒计时方法
          this.timer();
        })

      }
    },
    timer() {
      if (this.time > 0) {
        this.time--;
        // console.log(this.time);
        this.btnText = this.time + "s后重新获取验证码";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btnText = "获取验证码";
        this.disabled = false;
      }
    },
    /** 10s  计时**/
    goNext() {
      this.dialogVisible = true
      if (!this.canClick) return
      this.canClick = false
      this.sendShort = `${this.totalTime} s 确认`// 避免变成空
      this.cloak = setInterval(() => {
        this.totalTime--
        if (this.totalTime > 0) {
          this.sendShort = `${this.totalTime}s 确认`
        }
        if (this.totalTime <= 0) {
          window.clearInterval(this.cloak)
          this.sendShort = '确认'
          this.totalTime = 10
          this.canClick = true
        }
      }, 1000)
    },
    /** 10s  计时**/
    getNum() {
      if (this.canClick === true) {
        this.$router.push('/register_jj')
      } else {
        this.$message.warning('请阅读注册协议')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.register {
  height: 100vh;
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/img/User/register.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;

  .titleShow {
    display: inline-block;
    font-size: 20px;
    color: var(--el-color-primary);
    font-weight: bold;
    position: relative;
    line-height: 50px;
    border-bottom: 2px solid var(--el-color-primary);
  }

  .cardBox {
    width: 100%;
    flex-wrap: wrap;
    padding: 20px;

    .w50 {
      width: 40%;
      margin-right: 40px;
    }

    .check-box-btn {
      position: absolute;
      top: 0;
      right: 20px;
    }

  }

  ::v-deep(.el-checkbox__label) {
    display: none;
  }

  .box-card {
    width: 100%;
    height: 120px;
    font-size: 30px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: flex-start;
    margin-right: 40px;
    margin-bottom: 20px;
    border: 1px solid #e4e7ed;
    padding-left: 20px;
    position: relative;

    img {
      height: auto;
      width: 50px;
    }

    .flex_list {
      width: calc(100% - 70px);
      height: calc(100% - 20px);
      display: flex;
      flex-direction: column;

      p {
        font-size: 14px;
        line-height: 30px;
      }

      .p2 {
        word-break: break-word;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* 将显示的行数设置为 2 行 */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }

      .name {
        width: 100%;

      }
    }
  }

  .register_supplement {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 2;
    padding: 20px;

    .r_s_box {
      height: 100%;
      width: 100%;
      border-radius: 4px;
      border: 1px solid #e4e7ed;
      background-color: #fff;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, .12);
      overflow: hidden;
      transition: var(--el-transition-duration);
    }
  }

  .register_content {
    width: 800px;
    height: 600px;
    background: #FFFFFF;
    box-shadow: 0px 0px 18px 2px rgba(64, 147, 250, 0.1);
    border-radius: 4px;

    .title {
      text-align: center;
      position: relative;
      line-height: 50px;
      margin: 80px auto;
      font-size: 34px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #000000;
    }

    .title::after {
      content: ' ';
      position: absolute;
      height: 2px;
      width: 64px;
      bottom: 0;
      background: #1890FF;
      left: 50%;
      transform: translate(-50%, 0);
    }

    .steps-box {
      width: 40%;
      margin: 0 auto;

      .stepIcon {
        width: 40px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 20px;
        text-align: center;
        line-height: 40px;
        border: 1px solid #919191;
        color: #919191;
      }

      .active {
        border: 1px solid #3381FF;
        color: #3381FF;
      }

      ::v-deep(.el-step__line) {
        height: 1px;
        position: absolute;
        border-color: inherit;
        background-color: transparent;
        border: 1px dashed var(--el-text-color-placeholder);
      }

      .el-step.is-simple:not(:last-of-type) .el-step__title {
        max-width: none;
      }
    }
  }

  .itemForm {
    height: 50px;
    box-sizing: border-box;
    margin: 0 auto 40px;
    border: 1px solid #DCDCDC;
    border-radius: 4px;

    .el-input {
      width: 100%;
      height: 100%;
    }

    ::v-deep(.el-input__inner) {
      width: 100%;
      height: 100%;
      border: none !important;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 300;
      color: #333333 !important;
    }

    ::v-deep(.el-input__wrapper) {
      box-shadow: none;
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  .code {
    position: relative;
  }

  .getCode {
    position: absolute;
    right: 30px;
  }

  .btn_p {
    position: relative;
    font-size: 14px;
    text-align: center;
  }

  .btn_p::after {
    content: ' ';
    position: absolute;
    height: 2px;
    width: 30px;
    bottom: 0;
    background: #333333;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .btn {
    width: 100%;
    height: 44px;
    background: #1890FF;
    border-radius: 4px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 33px;
    margin: 0 auto 0;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .prefixUser {
    width: 22px;
    height: 22px;
    margin-right: 10px;
    background-image: url('../../assets/img/User/username.png');
    background-repeat: no-repeat;
  }

  .prefixMess {
    width: 22px;
    height: 22px;
    margin-right: 10px;
    background-image: url('../../assets/img/User/mess.png');
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .prefixPass {
    width: 22px;
    height: 22px;
    margin-right: 10px;
    background-image: url('../../assets/img/User/password.png');
    background-repeat: no-repeat;
  }

  .prefixUserRight {
    width: 1px;
    height: 34px;
    background: #DCDCDC;
    margin-right: 16px;
  }

  .register-title {
    width: 1000px;
    height: 98px;
    background: #ffffff;
    line-height: 98px;
    margin: 0 auto;
    display: flex;

    .logo-item {
      width: 137px;
      height: 34px;
      //background: url("./images/zhucetitle.png") no-repeat;
      background-size: 100% 100%;
      margin-top: 32px;
    }

    .world-item {
      width: 96px;
      height: 33px;
      line-height: 33px;
      text-align: center;
      margin-top: 33px;
      margin-left: 20px;
      color: #05B37B;
      border-left: 2px solid #E9E9E9;
    }

    .login-item {
      margin-left: 615px;
      font-size: 14px;
      color: #555555;

      em {
        color: #05B37B;
        cursor: pointer;
      }
    }
  }

  .register-image {
    height: 182px;
    line-height: 182px;
    text-align: center;
    font-size: 40px;
    color: #ffffff;
    width: 100%;
    /*background: #00CB8A;*/
    //background: url('./images/navbar.png') no-repeat;
    background-size: 100% 100%;
  }

  .register-type1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn_p {
      position: relative;
      font-size: 14px;
      text-align: center;
      margin-top: 10px;
    }

  }

  .register-type {
    width: 50%;
    margin: 0 auto;
    margin-top: 50px;
    /*display: flex;*/
    /*flex-direction: row;*/
    /*justify-content: space-between;*/

    img {
      display: block;
      width: 148px;
      height: 124px;
      /*border: 1px solid #33aef0;*/
      margin: 0 auto;
    }

    p {
      width: 100%;
      text-align: center;
      font-size: 18px;
      color: #333333;
      margin-top: 14px;
    }

    .agent {
      width: 290px;
      height: 312px;

      .el-button {
        width: 290px;
        height: 70px;
        background: #00cb8a;
      }

      .el-button--primary {
        border-color: transparent;
      }

      .agent-img {
        padding-top: 40px;
        width: 100%;
        height: 242px;
        background: white;
      }
    }

    .shipper:hover {
      transform: translate(0, -20px);
      transition: 1.5s;
      -webkit-transform: translate(0, -20px);
      -moz-transform: translate(0, -20px);
      -o-transform: translate(0, -20px);
      -ms-transform: translate(0, -20px);
    }

    .agent:hover {
      transform: translate(0, -20px);
      transition: 1.5s;
      -webkit-transform: translate(0, -20px);
      -moz-transform: translate(0, -20px);
      -o-transform: translate(0, -20px);
      -ms-transform: translate(0, -20px);
    }
  }

  .register-container {
    padding: 30px;
    width: 1000px;
    height: 510px;
    margin: 0 auto;


    .next-box {
      width: 400px;
      bottom: 20px;
      margin: 0 auto;

      .el-button {
        width: 100%;
        background: #00cb8a;
      }

      .el-button--primary {
        border-color: transparent;
      }
    }
  }

  .agreement {
    user-select: none;
    width: 100%;
    height: 500px;
    overflow: auto;
  }
}
</style>
