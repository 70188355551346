<template>
    <div class="content">
        <div class="announcementView">
            <div class="topImg">
                <div class="topName">{{IS_SHANJIAO?'山西焦煤集团新能源有限公司':'山西合邦电力科技有限公司'}}</div>
                <div class="flex" style="justify-content: space-around;">
                    <span class="flex" style="align-items: center;">
                        <div class="bulletinBoardLeft"></div>
                        <div class="bulletinBoard">公告栏</div>
                        <div class="bulletinBoardRight"></div>
                    </span>
                </div>
            </div>
            <div class="topLeftImg"></div>
            <div class="contentList">
                <el-scrollbar v-if="noticeList.length > 0" ref="scrollbarRef" style="padding-right: 40px;">
                    <div class="contentListItem" v-for="(item, index) in noticeList" :key="item.id" :id="'contentListItem'+item.id">
                        <div class="flex" style="justify-content: space-between;align-items: center;margin-bottom: 30px;">
                            <div class="title">
                                <div class="titleCircle"></div>
                                <div class="titleInfo">{{ item.noticeName }}</div>
                                <!-- <div class="titleBtn">{{ item.chaDay }}天后关闭</div> -->
                                <div class="titleBtn">{{ item.chaDate }}关闭</div>
                                <div class="subSuccess" v-show="item.isSubmitSuccess">
                                    <div class="subSuccessImg"></div>
                                    <span>提交成功</span>
                                </div>
                            </div>

                            <span class="time">{{ item.showStartTime }}</span>
                        </div>
                        <div class="flex" style="justify-content: space-between;align-items: center;background: linear-gradient(12deg, rgba(24,144,255,0.04) 0%, rgba(255,255,255,0) 100%);">
                            <div class="contentListItem_infos">
                                {{ item.noticeContent }}
                            </div>
                            <el-button
                                style="margin-left: 20px;border-color: #1890ff;color: #1890ff;"
                                @click="joinReport(item)"
                                class="cysbBtn"
                            >参与申报</el-button>
                        </div>
                        <div style="margin-top: 30px;padding: 0px 66px;" v-show="item.joinReportFlag">
                            <el-form
                                :ref="setDom"
                                :model="item.ruleForm"
                                :rules="rules"
                                label-width="85px"
                                class="demo-ruleForm"
                                label-position="left"
                            >
                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="主体名称：" prop="name">
                                            <el-input v-model="item.ruleForm.name" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="10">
                                        <el-form-item label="参与类型：" prop="region">
                                            <el-select v-model="item.ruleForm.region" placeholder="请选择">
                                                <el-option label="火电" value="火电" />
                                                <el-option label="光伏" value="光伏" />
                                                <el-option label="风电" value="风电" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="4">

                                    </el-col>
                                    <el-col :span="10">
                                        <el-form-item label="意向价格：" prop="price">
                                            <el-input v-model="item.ruleForm.price">
                                                <template #suffix>
                                                    <span>元/MWh</span>
                                                </template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="10">
                                        <el-form-item label="参与量：" prop="measure">
                                            <el-input v-model="item.ruleForm.measure">
                                                <template #suffix>
                                                    <span>MWh</span>
                                                </template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="4">

                                    </el-col>
                                    <el-col :span="10">
                                        <el-form-item label="联系人：" prop="contacts">
                                            <el-input v-model="item.ruleForm.contacts" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="10">
                                        <el-form-item label="验证码：" prop="code">
                                            <el-input v-model="item.ruleForm.code" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="4">
                                        <!-- <el-button v-if="!item.showSeconds" type="text" style="padding-left: 12px;" @click="getCode(item)">获取验证码</el-button>
                                        <el-button v-if="item.showSeconds" type="text" style="padding-left: 12px;" disabled>{{ item.seconds }}</el-button> -->
                                        <div @click="getCode(item)" style="padding: 0px 12px;">
                                            <img :id="'canvas_img'+item.id" src="" alt="" class="checkImg">
                                        </div>
                                    </el-col>
                                    <el-col :span="10">
                                        <el-form-item label="联系电话：" prop="phone">
                                            <el-input v-model="item.ruleForm.phone" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="备注：" prop="remark" class="remarkBox">
                                            <el-input type="textarea" v-model="item.ruleForm.remark" resize="none" :autosize="{minRows: 3, maxRows: 3}" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-form-item class="btnList" style="margin-bottom: 0px;" label-width="0px">
                                    <span>
                                        <el-button plain @click="cancleReport(item, index)">取消</el-button>
                                        <el-button type="primary" @click="submitForm(item, index)">
                                            提交
                                        </el-button>
                                    </span>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </el-scrollbar>
                <div v-else class="noData"></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref, onMounted, nextTick, onUnmounted, getCurrentInstance } from "vue";
import { getNoticeListApi, saveApplyApi, getCodeApi } from '@/api/announcementView'
import { ElMessage } from "element-plus";
const IS_SHANJIAO = ref(process.env.VUE_APP_SHANJIAO === 'true')
const { ctx: that } = getCurrentInstance()


const ruleFormRef = ref()
const ruleForm = reactive({
    name: '',
    region: '',
    price: '',
    measure: '',
    contacts: '',
    phone: '',
    code: '',
    remark: ''
})
var numPriceReg = /^([0-9]{1}|^[1-9]{1}\d{1,})(\.\d{1,2})?$/
const validateNumPriceRG = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('请输入数字(最多两位小数)'))
    } else {
        if (numPriceReg.test(value)) {
            callback()
        } else {
            callback(new Error('请输入数字(最多两位小数)'))
        }
    }
}
var numLiangReg = /^([0-9]{1}|^[1-9]{1}\d{1,})(\.\d{1,3})?$/
const validateNumLiangeRG = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('请输入数字(最多三位小数)'))
    } else {
        if (numLiangReg.test(value)) {
            callback()
        } else {
            callback(new Error('请输入数字(最多三位小数)'))
        }
    }
}
var checkTelephoneReg = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
const checkTelephone = (rule, value, callback) => {
    if (value === null || value.length === '') {
        return callback(new Error('请输入电话号码'))
    } else if (checkTelephoneReg.test(value)){
        callback()
    } else {
        return callback(new Error('请输入正确的号码格式'))
    }
}
const rules = reactive({
    name: [
        { required: true, message: '请输入', trigger: 'blur' },
        { max: 250, message: '最多可输入250个字', trigger: 'blur' },
    ],
    region: [
        {
            required: true,
            message: '请选择',
            trigger: 'change',
        },
    ],
    price: [
        {
            required: true,
            validator: validateNumPriceRG,
            trigger: 'blur'
        }
    ],
    measure: [
        {
            required: true,
            validator: validateNumLiangeRG,
            trigger: 'blur'
        }
    ],
    contacts: [
        { required: true, message: '请输入', trigger: 'blur' },
    ],
    phone: [
        { required: true, trigger: 'blur', validator: checkTelephone },
    ],
    code: [
        { required: true, message: '请输入', trigger: 'blur' },
    ]
})
const cancleReport = (item, index) => {
    let currentIndex = 0
    let scrollHeight = 0
    for (let i = 0; i < noticeList.value.length; i++) {
        if (item.id == noticeList.value[i].id) {
            noticeList.value[i].joinReportFlag = false
            domList.value[index].resetFields()
            currentIndex = i
            nextTick(() => {
                console.log(currentIndex)
                for (let i = 0; i < currentIndex; i++) {
                    let itemDom = document.getElementById('contentListItem'+noticeList.value[i].id)
                    console.log(itemDom.offsetHeight)
                    scrollHeight += itemDom.offsetHeight
                }
                scrollbarRef.value.setScrollTop(scrollHeight)
            })
        }
    }
}
const getCode = (item) => {
    for (let i = 0; i < noticeList.value.length; i++) {
        if (item.id == noticeList.value[i].id) {
            // if (checkTelephoneReg.test(noticeList.value[i].ruleForm.phone)) {
            //     noticeList.value[i].showSeconds = true
            //     noticeList.value[i].seconds
            //     let timer = setInterval(() => {
            //         noticeList.value[i].seconds--
            //         if (noticeList.value[i].seconds == 0) {
            //             noticeList.value[i].showSeconds = false
            //             noticeList.value[i].seconds = 60
            //             clearInterval(timer)
            //         }
            //     }, 1000)
            //     // let data = new FormData()
            //     // data.append('userPhone', noticeList.value[i].ruleForm.phone)
            //     let data = {
            //         userPhone: noticeList.value[i].ruleForm.phone
            //     }
            //     getCodeApi(data).then(res => {
            //         console.log(res)
            //     })
            // } else {
            //     ElMessage.error('请输入正确的联系电话')
            // }
            getCodeApi().then(res => {
                console.log(res)
                let canvas_img = document.querySelector('#canvas_img'+item.id);
                console.log(res.headers['content-type'].split(';')[0])
                let typeStr = res.headers['content-type'].split(';')[0]
                let imageType = typeStr.split('/')[1];
                const blob = new Blob([res.data], { type: imageType });
                const imageUrl = (window.URL || window.webkitURL).createObjectURL(blob);
                canvas_img.src = imageUrl;
                canvas_img.onload = function(e) {
                    window.URL.revokeObjectURL(canvas_img.src);  //释放URL.createObjectURL()创建的对象
                };
                noticeList.value[i].ruleForm.snowflakeValue = res.headers.snowflakevalue
            })
        }
    }
}
const domList = ref([])
const setDom = (el) => {
    domList.value.push(el)
}
const submitForm = (item, index) => {
    console.log(domList.value[index])
    console.log(item)
    domList.value[index].validate((valid, fields) => {
            if (valid) {
                console.log('submit!')
                let data = {
                    "bulkName": item.ruleForm.name,
                    "contacts": item.ruleForm.contacts,
                    // "createBy": "",
                    // "createTime": "",
                    // "delFlag": "",
                    // "id": '',
                    "intendedPrice": item.ruleForm.price,
                    "involvedNum": item.ruleForm.measure,
                    "involvedType": item.ruleForm.region,
                    "noticeId": item.id,
                    "remark": item.ruleForm.remark,
                    // "submitDate": "",
                    "telephone": item.ruleForm.phone,
	                "code": item.ruleForm.code,
                    "snowflakeValue": item.ruleForm.snowflakeValue
                }
                let currentIndex = 0
                let scrollHeight = 0
                saveApplyApi(data).then(res => {
                    console.log(res)
                    if (res.code == 200) {
                        for (let i = 0; i < noticeList.value.length; i++) {
                            if (item.id == noticeList.value[i].id) {
                                noticeList.value[i].joinReportFlag = false
                                currentIndex = i
                                nextTick(() => {
                                    for (let i = 0; i < currentIndex; i++) {
                                        let itemDom = document.getElementById('contentListItem'+noticeList.value[i].id)
                                        scrollHeight += itemDom.offsetHeight
                                    }
                                    scrollbarRef.value.setScrollTop(scrollHeight)
                                })
                                noticeList.value[i].isSubmitSuccess = true
                                setTimeout(() => {
                                    noticeList.value[i].isSubmitSuccess = false
                                }, 1500)
                                // that.$forceUpdate()
                            }
                        }
                        console.log(noticeList.value)
                        noticeList.value.splice(0,1,noticeList.value[0])
                        cancleReport(item, index)
                    }
                }).catch((err) => {
                    console.log(err.message)
                    if ('验证码失效' == err.message) {
                        getCode(item)
                    }
                })
            } else {
                console.log('error submit!', fields)
            }
    })

}
// 当前日期
var date = new Date();
const currentDayStr = date.getTime()
const noticeList = ref([])
const getNoticeList = () => {
    getNoticeListApi().then(res => {
        console.log(res)
        if (res.code == 200) {
            console.log(res)
            noticeList.value = res.data
            for (let i = 0; i < noticeList.value.length; i++) {
                noticeList.value[i].isSubmitSuccess = false
                noticeList.value[i].joinReportFlag = false
                noticeList.value[i].ruleForm = {
                    name: '',
                    region: '',
                    price: '',
                    measure: '',
                    contacts: '',
                    phone: '',
                    code: '',
                    remark: '',
                    snowflakeValue: ''
                }
                noticeList.value[i].seconds = 60
                noticeList.value[i].showSeconds = false

                let endDateStr = (new Date(noticeList.value[i].showEndTime + ' 23:59:59')).getTime()
                let chaDayStr = endDateStr - currentDayStr
                let chaDay = 0
                let chaDayHours = 0
                if (chaDayStr < (1 * 24 * 60 * 60 * 1000)) {
                    chaDayHours = chaDayStr/(1 * 60 * 60 * 1000)
                    noticeList.value[i].chaDate = Math.ceil(chaDayHours) + '小时后'
                } else {
                    chaDay = chaDayStr/(1 * 24 * 60 * 60 * 1000)
                    noticeList.value[i].chaDate = Math.ceil(chaDay) + '天后'
                }
            }
        } else {
            noticeList.value = []
        }
    }).catch(() => {
        noticeList.value = []
    })
}
const scrollbarRef = ref()
const joinReport = (item) => {
    let currentIndex = 0
    let scrollHeight = 0
    for (let i = 0; i < noticeList.value.length; i++) {
        noticeList.value[i].joinReportFlag = false
        domList.value[i].resetFields()
        if (item.id == noticeList.value[i].id) {
            noticeList.value[i].joinReportFlag = true
            getCode(item)
            currentIndex = i
            nextTick(() => {
                // // 当前id公告内容的高度
                // let itemDom = document.getElementById('contentListItem'+item.id)
                // let childDomList = itemDom.childNodes
                // let currentIdHeight = childDomList[1].offsetHeight
                // if (currentIndex == 0) {
                //     scrollHeight += (100 + currentIdHeight)
                // }
                // for (let i = 0; i < currentIndex; i++) {
                //     let itemDom = document.getElementById('contentListItem'+noticeList.value[i].id)
                //     console.log(itemDom.offsetHeight)
                //     scrollHeight += (itemDom.offsetHeight + 100 + currentIdHeight)
                // }
                // scrollbarRef.value.setScrollTop(scrollHeight)
                for (let i = 0; i < currentIndex; i++) {
                    let itemDom = document.getElementById('contentListItem'+noticeList.value[i].id)
                    console.log(itemDom.offsetHeight)
                    scrollHeight += itemDom.offsetHeight
                }
                scrollbarRef.value.setScrollTop(scrollHeight)

            })
        }
    }
}
onMounted(() => {
    getNoticeList()
});

</script>

<style lang='less' scoped>
.content {
    width: 100%;
    height: 100vh;
    padding: 20px;
    background-image: url('../assets/img/announcementViewBg.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: auto;
    .announcementView {
        width: 1030px;
        height: 763px;
        margin: auto;
        position: absolute;
        top: 0;bottom: 0;left: 0;right: 0;
        .topLeftImg {
            width: 212px;
            height: 173px;
            background-image: url('../assets/img/announcementViewBgTopLeft.png');
            background-repeat: no-repeat;
            position: absolute;
            top: 0px;
            left: 6px;
        }
        .topImg {
            width: 969px;
            height: 224px;
            background-image: url('../assets/img/announcementViewBgTop.png');
            background-repeat: no-repeat;
            position: absolute;
            top: 63px;
            left: 25px;
            .topName {
                height: 19px;
                font-size: 20px;
                // font-family: Source Han Sans CN;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                line-height: 22px;
                margin-top: 22px;
                margin-bottom: 20px;
                text-align: center;
            }
            .bulletinBoardLeft {
                width: 180px;
                height: 6px;
                background-image: url('../assets/img/bulletinBoardLeft.png');
                background-repeat: no-repeat;
            }
            .bulletinBoardRight {
                width: 180px;
                height: 6px;
                background-image: url('../assets/img/bulletinBoardRight.png');
                background-repeat: no-repeat;
            }
            .bulletinBoard {
                height: 34px;
                font-size: 46px;
                font-family: YouSheBiaoTiHei;
                font-weight: 400;
                color: #1890FF;
                line-height: 34px;
                text-shadow: 0px 3px 7px rgba(0,0,0,0.2);
                margin-left: 16px;
                margin-right: 16px;
            }
        }
        .contentList {
            width: 100%;
            height: 590px;
            position: absolute;
            bottom: 0px;
            background: #FFFFFF;
            box-shadow: 0px 6px 25px 2px rgba(24,144,255,0.13);
            border-radius: 8px;
            padding: 16px 0px 20px 40px;
            .contentListItem {
                min-height: 155px;
                width: 100%;
                padding: 40px 10px 30px 10px;
                border-bottom: 1px solid #e6e6e6;
                .title {
                    display: flex;
                    align-items: center;
                    .titleCircle {
                        min-width: 8px;
                        height: 8px;
                        background: #E89E41;
                        border-radius: 50%;
                    }
                    .titleInfo{
                        // width: 223px;
                        min-height: 21px;
                        font-size: 22px;
                        font-family: Source Han Sans CN;
                        font-weight: bold;
                        color: #1890FF;
                        line-height: 30px;
                        margin-right: 12px;
                        margin-left: 13px;
                    }
                    .titleBtn {
                        min-width: 110px;
                        flex-shrink:0;
                        height: 24px;
                        background: #FFAB0A;
                        border-radius: 4px;
                        text-align: center;
                        font-size: 16px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 24px;
                        padding: 0px 12px;
                        margin-right: 59px;
                    }
                    .subSuccess {
                        width: 153px;
                        flex-shrink:0;
                        height: 32px;
                        background: #F0FFF5;
                        box-shadow: 0px 3px 7px 0px rgba(51,51,51,0.08);
                        border-radius: 4px;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        span {
                            font-size: 18px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            color: #1AC453;
                            line-height: 32px;
                        }
                        .subSuccessImg {
                            width: 18px;
                            height: 18px;
                            margin-right: 12px;
                            margin-left: 16px;
                            background-image: url('../assets/img/subSuccess.png');
                            background-repeat: no-repeat;
                        }
                    }
                }

                .time {
                    width: 180px;
                    font-size: 18px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    margin-left: 20px;
                    text-align: right;
                    flex-shrink: 0;
                }
                .contentListItem_infos {
                    font-size: 18px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    line-height: 32px;
                    padding-left: 25px;
                }
                .el-button.cysbBtn:focus, .el-button.cysbBtn:hover {
                    background-color: rgba(24, 144, 255, 0.05);
                }
                .demo-ruleForm {
                    .el-select {
                        width: 100%;
                        .el-input__inner {
                            width: 100% !important;
                        }
                    }
                    ::v-deep(.el-form-item.btnList) {
                        .el-form-item__content {
                            justify-content: space-around;
                        }
                    }
                    .checkImg {
                        height: 32px;
                        width: auto;
                    }
                    .el-input__wrapper {
                        padding: 0 11px !important;
                    }
                    ::v-deep(.remarkBox .el-form-item__label) {
                        padding-left: 9.5px !important;
                    }
                }
            }
            .noData {
                width: 182px;
                height: 222px;
                background-image: url('../assets/img/empty.png');
                background-repeat: no-repeat;
                // position: absolute;
                // left: 0px;right: 0px;top: 0px;bottom: 0px;
                margin-left: calc(50% - 91px);
                margin-top: calc(50% - 360px);
            }
        }
    }
}
</style>
