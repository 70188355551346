// import 'zone.js'; // for angular subapp
import { registerMicroApps, runAfterFirstMounted, start, setDefaultMountApp } from 'qiankun';
import actions from './action';
import { ElLoading } from 'element-plus';
import _ from 'lodash';
import router from '@/router';
const loader = () => {
    // console.log(loading);
};
const appsData = [
    {
        name: 'powerTrading',
        entry: process.env.VUE_APP_POWER_TRADING,
        container: '#subapp-viewport',
        activeRule: '/powerTrading',
        loader,
        props: {
            name: '电力交易1.0'
        },
        sandbox: true
    },
    {
        name: 'enterpriseSearch',
        entry: process.env.VUE_APP_ENTER_SEARCH,
        container: '#subapp-viewport',
        activeRule: '/enterpriseSearch',
        loader,
        sandbox: true
    },
    {
        name: 'energyTesting',
        entry: process.env.VUE_APP_ENTER_TESTING,
        container: '#subapp-viewport',
        activeRule: '/energyTesting',
        loader,
        sandbox: true,
        props: {
            actions,
            routerBase: '/energyTesting', // 子应用的路由前缀(router的base)
            routerList: [] // 子应用的路由列表
        }
    },
    {
        name: 'transactionCalendar',
        entry: process.env.VUE_APP_TRANSACTION_CALENDAR,
        container: '#subapp-viewport',
        loader,
        activeRule: '/transactionCalendar',
        sandbox: true,
        props: {
            routerBase: '/transactionCalendar', // 子应用的路由前缀(router的base)
            routerList: [] // 子应用的路由列表
        }
    },
    {
        name: 'spotElectricityPriceForecast',
        entry: process.env.VUE_APP_SPOT_ELECTRICITY_PRICE_FORECAST,
        container: '#subapp-viewport',
        activeRule: '/spotElectricityPriceForecast',
        sandbox: true,
        loader,
        props: {
            routerBase: '/spotElectricityPriceForecast', // 子应用的路由前缀(router的base)
            routerList: [] // 子应用的路由列表
        }
    },
    {
        name: 'holdKanban',
        entry: process.env.VUE_APP_HOLD_KANBAN,
        container: '#subapp-viewport',
        activeRule: '/holdKanban',
        sandbox: true,
        loader,
        props: {}
    },
    {
        name: 'estimateDaypartingQuotation',
        entry: process.env.VUE_APP_ESTIMATE_DAYPARTING_QUOTATION,
        container: '#subapp-viewport',
        activeRule: '/estimateDaypartingQuotation',
        loader,
        sandbox: true
    },
    {
        name: 'planReview',
        entry: process.env.VUE_APP_PLAN_REVIEW,
        container: '#subapp-viewport',
        activeRule: '/planReview',
        loader,
        sandbox: true
    },
    {
        name: 'planReviewE',
        entry: process.env.VUE_APP_PLAN_REVIEWE,
        container: '#subapp-viewport',
        activeRule: '/planReviewE',
        loader,
        sandbox: true
    },
    {
        name: 'customerManagement',
        entry: process.env.VUE_APP_CUSTOMER_MANAGEMENT,
        container: '#subapp-viewport',
        activeRule: '/customerManagement',
        loader,
        sandbox: true
    },
    {
        name: 'settlementManagement',
        entry: process.env.VUE_APP_SETTLEMENT_MANAGEMENT,
        container: '#subapp-viewport',
        activeRule: '/settlementManagement',
        loader,
        sandbox: true
    },
    {
        name: 'HistoricalElectricityConsumption',
        entry: process.env.VUE_APP_HISTORICAL_ELECTRICITY_CONSUMPTION,
        container: '#subapp-viewport',
        activeRule: '/HistoricalElectricityConsumption',
        loader,
        sandbox: true
    },
    {
        name: 'disclosureData',
        entry: process.env.VUE_APP_DISCLOSURE_DATA,
        container: '#subapp-viewport',
        activeRule: '/disclosureData',
        loader,
        sandbox: true
    },
    {
        name: 'operationManagement',
        entry: process.env.VUE_APP_OPERATION_MANAGEMENT_DATA,
        container: '#subapp-viewport',
        activeRule: '/operationManagement',
        loader,
        sandbox: true
    },
    {
        name: 'application',
        entry: process.env.VUE_APP_APPLICATION,
        container: '#subapp-viewport',
        activeRule: '/application',
        loader,
        sandbox: true
    },
    {
        name: 'backstageManagement',
        entry: process.env.VUE_APP_BACKSTAGE_MANAGEMENT,
        container: '#subapp-viewport',
        activeRule: '/backstageManagement',
        loader,
        sandbox: true
    },
    {
        name: 'retailMarket',
        entry: process.env.VUE_APP_RETAILMARKET,
        container: '#subapp-viewport',
        activeRule: '/retailMarket',
        loader,
        sandbox: true
    },
    {
        name: 'cgd',
        entry: process.env.VUE_APP_CABLE_GAS,
        container: '#subapp-viewport',
        activeRule: '/cgd',
        loader,
        sandbox: true
    },
    {
        name: 'weatherForecast',
        entry: process.env.VUE_APP_WEATHERFORECAST,
        container: '#subapp-viewport',
        activeRule: '/weatherForecast',
        loader,
        sandbox: true
    },
    {
        name: 'noticeRelease',
        entry: process.env.VUE_APP_NOTICE_RELEASE,
        container: '#subapp-viewport',
        activeRule: '/noticeRelease',
        loader,
        sandbox: true
    },
    {
        name: 'energyStorage',
        entry: process.env.VUE_APP_ENERGYSTORAGE,
        container: '#subapp-viewport',
        activeRule: '/energyStorage',
        loader,
        sandbox: true
    },
    {
        name: 'energyMaintenance',
        entry: process.env.VUE_APP_ENERGYMAINTENANCE,
        container: '#subapp-viewport',
        activeRule: '/energyMaintenance',
        loader,
        sandbox: true
    },
    {
        name: 'workflow',
        entry: process.env.VUE_APP_WORKFLOWAPP,
        container: '#subapp-viewport',
        activeRule: '/workflow',
        loader,
        props: {
            name: '储能运维平台工作流',
            routerBase: '/workflow',
            actions
        },
        sandbox: true
    },
    {
        name: 'projectManagement',
        entry: process.env.VUE_APP_PROJECTMANAGEMENT,
        container: '#subapp-viewport',
        activeRule: '/projectManagement'
    },
    {
        name: 'projectManagementInside',
        entry: process.env.VUE_APP_PROJECTMANAGEMENTINSIDE,
        container: '#subapp-viewport',
        activeRule: '/projectManagementInside'
    },
    {
        name: 'intelligentTerminal',
        entry: process.env.VUE_APP_INTELLIGENTTERMINAL,
        container: '#subapp-viewport',
        activeRule: '/intelligentTerminal'
    },
    {
        name: 'powerGrid',
        entry: process.env.VUE_APP_POWERGRID,
        container: '#subapp-viewport',
        activeRule: '/powerGrid'
    },
    {
        name: 'virtualPowerPlant',
        entry: process.env.VUE_APP_VIRTUALPOWERPLANT,
        container: '#subapp-viewport',
        activeRule: '/virtualPowerPlant',
        sandbox: {
            strictStyleIsolation:true,// 开启样式隔离
        },
        props: {
            actions,
        }
    },
    {
        name: 'tenderingAndBidding',
        entry: process.env.VUE_APP_TENDERINGANDBIDDING,
        container: '#subapp-viewport',
        activeRule: '/tenderingAndBidding',
        loader,
        sandbox: true
    },
];
const usedRoute = [];

// 动态注册子应用的函数
function dynamicRegisterApps(app) {
    console.log(app);
    const apps = app;
    // 可以在这里根据条件过滤出需要注册的子应用
    // 比如根据用户角色、权限、配置等条件
    registerMicroApps(apps, {
        beforeLoad: [
            /* ... */
        ],
        beforeMount: [
            /* ... */
        ],
        afterMount: [
            /* ... */
        ],
        afterUnmount: [
            /* ... */
        ]
    });
}

router.beforeEach(async (to, from, next) => {
    let pathname = location.pathname;
    let url = '/' + pathname.split('/')[1];
    let arr = appsData.filter((item) => {
        return item.activeRule === url;
    });
    dynamicRegisterApps(arr);
    next();
});

// // 你可以根据实际情况调用这个函数，比如在用户点击某个按钮时
// let appf = document.getElementById('appf')
// // 在父元素上添加点击事件监听器
// if (appf) {
//     appf.addEventListener('click', (event) => {
//         console.log("111111")
//         // event.target 是被点击的元素
//         const clickedElement = event.target;
//         // is-active
//         // 检查被点击的元素是否是我们想要监听点击事件的元素
//         // 例如，我们只想监听 class 包含 'menu-item' 的元素的点击事件
//         if (clickedElement.classList.contains('el-menu-item') && clickedElement.classList.contains('is-active')) {
//             const dataUrl = clickedElement.getAttribute('data-url');
//             if (dataUrl) {
//                 let url = '/' + dataUrl.split('/')[1];
//                 let arr = appsData.filter(item => {
//                     return item.activeRule === url
//                 })
//                 dynamicRegisterApps(arr)
//             }
//             // 你可以通过 clickedElement 来访问被点击元素的属性，例如 id、data-* 属性等
//         }
//     });
// }
// window.addEventListener('load', (event)=> {
//     console.log("22222")
//     let pathname = location.pathname
//     let url = '/' + pathname.split('/')[1];
//     let arr = appsData.filter(item => {
//         return item.activeRule === url
//     })
//     dynamicRegisterApps(arr)
// });
// 或者在应用启动时基于某些条件判断是否立即注册子应用
// if () {
// dynamicRegisterApps();
// }

// 启动 qiankun
start({
    prefetch: true
    // 其他配置...
});
