<template>
  <div class="login">

    <div class="login_box">
      <div class="loginTitle">电缆气体监测系统</div>
      <el-form
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules"
          class="login-form"
      >
        <div class="title">欢迎使用</div>
        <el-form-item prop="username" style="margin-bottom: 40px;">
          <el-input
              v-model="loginForm.username"
              type="text"
              auto-complete="off"
              placeholder="账号"
          >
            <!-- <svg-icon
              slot="prefix"
              icon-class="user"
              class="el-input__icon input-icon"
            /> -->

            <template #prefix>
              <div class="prefixUser"></div>
              <div class="prefixUserRight"></div>
            </template>
<!--            <span slot="prefix" class="el-input__icon usernameImg"></span>-->
          </el-input>
        </el-form-item>
        <el-form-item prop="password" style="margin-bottom: 7px;">
          <el-input
              v-model="loginForm.password"
              type="password"
              auto-complete="off"
              placeholder="密码"
              @keyup.enter.native="handleLogin"
              show-password
          >
            <template #prefix>
              <div class="prefixPass"></div>
              <div class="prefixPassRight"></div>
            </template>
<!--            &lt;!&ndash; <svg-icon-->
<!--              slot="prefix"-->
<!--              icon-class="password"-->
<!--              class="el-input__icon input-icon"-->
<!--            /> &ndash;&gt;-->
<!--            <span slot="prefix" class="el-input__icon passImg"></span>-->
          </el-input>
        </el-form-item>
        <el-form-item style="width: 100%">
          <el-checkbox v-model="checked">记住密码</el-checkbox>
        </el-form-item>
        <el-form-item style="width: 100%">
          <el-button
              :loading="loading"
              size="large"
              type="primary"
              style="width: 100%; border-radius: 4px"
              @click.native.prevent="handleLogin"
          >
            <span style="font-size: 20px" v-if="!loading">登 录</span>
            <span style="font-size: 20px" v-else>登 录 中...</span>
          </el-button>
        </el-form-item>
      </el-form>
      <!--  底部  -->
      <!--    <div class="el-login-footer">-->
      <!--      <span>Copyright © 2018-2022 ruoyi.vip All Rights Reserved.</span>-->
      <!--    </div>-->
    </div>
  </div>
</template>

<script>
// import { getCodeImg } from "@/api/login";
import Cookies from "js-cookie";
import { encrypt, decrypt } from "@/utils/jsencrypt";
// import { setToken } from "@/utils/auth";

import JSEncrypt from "jsencrypt";
import {ElMessage} from "element-plus";
import {setLocal} from "@/utils/formExtend";
import {login} from "@/api";
export default {
  name: "Login",
  data() {
    return {
      checked: false,
      codeUrl: "",
      loginForm: {
        username: "",
        password: "",
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "请输入您的账号" },
        ],
        password: [
          { required: true, trigger: "blur", message: "请输入您的密码" },
        ],
      },
      loading: false,
      // 验证码开关
      captchaOnOff: true,
      // 注册开关
      register: false,
      redirect: undefined,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    // this.getCode();
    this.getCookie();
  },
  methods: {
    getCode() {
      getCodeImg().then((res) => {
        this.captchaOnOff =
            res.captchaOnOff === undefined ? true : res.captchaOnOff;
        if (this.captchaOnOff) {
          this.codeUrl = "data:image/gif;base64," + res.img;
          this.loginForm.uuid = res.uuid;
        }
      });
    },
    getCookie() {
      const username = Cookies.get("username");
      const password = Cookies.get("password");
      const rememberMe = Cookies.get("rememberMe");
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password:
            password === undefined ? this.loginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
      };
    },
    handleLogin() {
      this.loading = true;
      const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCk2nvbv1V92I7wodOVGhAezEHrRHsPj3XQRzLEUYzhkXSqTAVDJrC3fS8c8bz2NDIROr5XzOmgTVrDIFL/kYSjc79Q+65684pYMN2R+pZKplB8hlUhJ5LscrES43TquLM6Oycp98D64tqtGm/XIURoi4b9iDQpVFNFqk3O1K919wIDAQAB';
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(publicKey);
      const encName = encrypt.encrypt(this.loginForm.username);
      const encPassword = encrypt.encrypt(this.loginForm.password);

      var data={
        username: encName,
        password: encPassword,
        owner: 'pta'
      }
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          login(data).then(res => {
            ElMessage.success('登录成功')
            const token = res.data.token
            this.loading= false
            setLocal('token', token, 1000 * 60 * 60)
            // 告诉父元素登录成功了
            this.$emit('isSuccess', 'login')
            //router.push(router.currentRoute.value.query.f)


          }).catch(error => {

          })


          // this.$store
          //     .dispatch("Login", data)
          //     .then(() => {
          //       this.loading = false;
          //       ElMessage.success('登录成功')
          //       const token = res.data.token
          //       setLocal('token', token, 1000 * 60 * 60)
          //       // 告诉父元素登录成功了
          //       this.emit('isSuccess', true)
          //       // console.log("...");
          //       // this.$router.push({ path: "/monitor/realTimeMonitoring" }).catch(() => {});
          //     })
          //     .catch(() => {
          //       this.loading = false;
          //     });
        }
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="less" scoped>
.login {
  width:100%;
  height: 100%;
  background-image: url("../assets/images/background.png");
  background-size: 100% 100%;
  .prefixUser {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    background-image: url('../assets/img/userIcon_sj.png');
    background-repeat: no-repeat;
  }

  .prefixUserRight {
    width: 2px;
    height: 16px;
    background: #1890FF;
    margin-right: 16px;
  }

  .prefixPass {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    background-image: url('../assets/img/passwordIcon_sj.png');
    background-repeat: no-repeat;
  }

  .prefixPassRight {
    width: 2px;
    height: 16px;
    background: #1890FF;
    margin-right: 16px;
  }
  .left {
    width: 50%;

    & > p {
      font-size: 40px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 73px;
      display: flex;
      img {
        height: 73px;
        width: 90px;
      }
    }
    img {
      display: inline-block;
      height: auto;
      width: 80%;
    }
  }
}

.title {
  margin: 20px auto 40px auto;
  text-align: left;
  color: #1890ff;
  font-size: 30px;
}
.login_box {
  position: fixed;
  top:50%;
  transform: translate(0 ,-50%);
  right:200px;
  border-radius: 10px;
  // background: #fff;
  width: 400px;
  padding: 25px;
  h1{
    font-size: 30px;
    font-family: FZZhengHeiS-DB-GB;
    font-weight: 400;
    color: #1890FF;
    text-align: center;
  }
  .loginTitle {
    font-size: 30px;
    font-family: FZZhengHeiS-DB-GB;
    font-weight: 400;
    color: #1890FF;
    text-align: center;
    margin-bottom: 23px;
  }
  // box-shadow: -2px 0px 24px 0px rgba(127, 127, 127, 0.24);
}
.login-form {
  border-radius: 10px;
  background: #ffffff;
  width: 383px;
  height:378px;
  padding: 40px 33px 50px 33px;

  .el-input {
    height: 44px;
    font-size: 21px;
    input {
      height: 44px;
    }
    //.el-input__inner {
    //  padding-left: 50px;
    //}
  }

  .input-icon {
    height: 44px;
    width: 14px;
    margin: 0px 0px;
  }
  .title {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin: 0px 0px 29px 2px;
  }
  .usernameImg {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url('../assets/images/use.png');
    background-repeat: no-repeat;
    margin: 10px;
  }
  .passImg {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url('../assets/images/pass.png');
    background-repeat: no-repeat;
    margin: 10px;
  }
}

.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}

.login-code {
  width: 33%;
  height: 38px;
  float: right;

  img {
    cursor: pointer;
    vertical-align: middle;
  }
}

.el-login-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}

.login-code-img {
  height: 38px;
}
</style>
