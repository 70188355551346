<template>
  <div>
    <el-form style="width: 400px" :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="80px"
      label-position="top">
      <el-form-item label="旧密码" prop="oldPassword">
        <el-input v-model="ruleForm.oldPassword" show-password type="password"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input v-model="ruleForm.newPassword"  show-password type="password"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="secondNewPassword">
        <el-input v-model="ruleForm.secondNewPassword"  show-password type="password"></el-input>
      </el-form-item>
      <div class="form-reset-submit">
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button style="margin-right: 20px" @click="reset">关闭</el-button>

      </div>
    </el-form>
  </div>
</template>
<script>
import { checkPassword } from '@/utils/common'
// import { resetPassword } from '@/api/UserInfo'
import {changePassword} from "@/api/UserInfo";
import JSEncrypt from "jsencrypt";
import actions from "@/action";

export default {
  data () { // 初始化数据
    return {
      ruleForm: {
        oldPassword: '',
        newPassword: '',
        secondNewPassword: '',
      },
      rules: {
        oldPassword: [
          {required: true, message: '旧密码不能为空', trigger: 'blur'},
        ],
        newPassword: [
          {required: true, message: '请输入新密码', trigger: 'blur'},
          { validator: this.validateNewPassword, trigger: 'blur' },
          {
            pattern: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,.\/]).{8,24}$/,
            message: '密码包含字母数字特殊字符长度8到24位',
            trigger: 'change'
          },
        ],
        secondNewPassword: [
          {required: true, message: '请输入确认密码', trigger: 'blur'},
          { validator: this.validateConfirmPassword, trigger: 'blur' },
          {
            pattern: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,.\/]).{8,24}$/,
            message: '密码包含字母数字特殊字符长度8到24位',
            trigger: 'change'
          },
        ]
      }
    }
  },
  methods: { // 定义函数
    validateOldPassword (rule, value, callback) {
      if (!value) return callback(new Error('密码不能为空'))
      return callback()
    },
    validateNewPassword (rule, value, callback) {
      if (!value) return callback(new Error('密码不能为空'))
      // if (!checkPassword(value)) return callback(new Error('密码长度为8-16位且必须包含大小写字母、数字和特殊字符串'))
      if (this.ruleForm.newPassword !== this.ruleForm.secondNewPassword) return callback(new Error('新密码与确认密码不相同'))
      return callback()
    },
    validateConfirmPassword (rule, value, callback) {
      if (!value) return callback(new Error('密码不能为空'))
      // if (!checkPassword(value)) return callback(new Error('密码长度为8-16位且必须包含大小写字母、数字和特殊字符串'))
      if (this.ruleForm.newPassword !== this.ruleForm.secondNewPassword) return callback(new Error('新密码与确认密码不相同'))
      this.$refs.ruleForm.validateField('newPassword')
      return callback()
    },
    reset () {
      this.$refs.ruleForm.resetFields()
      let path =  localStorage.getItem('userPath')
      this.$router.replace(path)
    },
    onSubmit () {
      this.$refs.ruleForm.validate((result, object) => {
        if (result) {
          this.$emit('uploadPassword', { loading: true })
          const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCk2nvbv1V92I7wodOVGhAezEHrRHsPj3XQRzLEUYzhkXSqTAVDJrC3fS8c8bz2NDIROr5XzOmgTVrDIFL/kYSjc79Q+65684pYMN2R+pZKplB8hlUhJ5LscrES43TquLM6Oycp98D64tqtGm/XIURoi4b9iDQpVFNFqk3O1K919wIDAQAB';
          const encrypt = new JSEncrypt();
          encrypt.setPublicKey(publicKey);
          changePassword({
            oldPassword:  encrypt.encrypt(this.ruleForm.oldPassword),
            newPassword: encrypt.encrypt( this.ruleForm.newPassword),
            secondNewPassword: encrypt.encrypt(this.ruleForm.secondNewPassword)
          }).then(res => {
            this.$emit('uploadPassword', { loading: false })
            if (res.code === 200) {
              this.reset()
              this.$message({
                type: 'success',
                message: '修改密码成功，请重新登录',
                duration: 1000,
                onClose: () => {
                  // localStorage.clear();
                  // 退出登录
                  actions.setGlobalState({noToken: true, activePath: '/'});
                  localStorage.removeItem('appid')
                  // 退出登录去掉新增测算记录信息
                  localStorage.removeItem('createCalculationForm');
                  localStorage.removeItem('createCalculationTable');
                  this.$router.push({ path: "/" });
                }
              })
            } else {
              this.$message({ type: 'error', message: '修改密码失败' })
            }
          }, error => {
            // this.$message({ type: 'error', message: '修改密码失败' })
            this.$emit('uploadPassword', { loading: false })
          })
        }
      })
    }
  },
  components: { // 解构映射到组件

  }
}
  /*
  data() { // 初始化数据
    return {}
  },
  methods: { // 定义函数

  },
  components: { // 解构映射到组件

  }
  beforeCreate () { // 创建前状态

  },
  created () { // 创建完毕状态

  },
  beforeMount () { // 初始化完成前状态

  },
  mounted() { //初始化完成后的回调函数

  },
  beforeUpdate() { // 更新前状态

  },
  updated() { // 更新完成状态

  },
  beforeDestroy() { // 销毁前状态

  },
  destroyed() { // 销毁完成状态

  },
  computed: { // 计算属性

  },
  watch: { // 深度监视，数据一旦发生改变，立马更新保存数据

  },
  */
</script>
<style scoped lang='less'>
.form-reset-submit {
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-form-item{
  margin-bottom: 32px;
}
</style>
