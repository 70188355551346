<template>
  <div class="login">
    <div class="topTitle">
      面向高承载需求的县域配电网安全运行控制综合管理系统
    </div>
    <div style="text-align: right;">
      <el-form ref="loginRef" :model="loginForm" :rules="loginRules" class="login-form">
        <h3 class="title">用户登录</h3>
        <el-form-item prop="username" style="padding-left: 11px;">
          <el-input v-model="loginForm.username" type="text" size="large" auto-complete="off" placeholder="用户名">
            <template #prefix>
              <img :src="loginUserSrc" alt="">
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password" style="padding-left: 11px;">
          <el-input v-model="loginForm.password" type="password" size="large" auto-complete="off" placeholder="密码"
                    @keyup.enter="handleLogin">
            <template #prefix>
              <img :src="loginPassSrc" alt="">
            </template>
          </el-input>
        </el-form-item>
        <!-- <el-form-item prop="code" v-if="captchaEnabled">
            <el-input v-model="loginForm.code" size="large" auto-complete="off" placeholder="验证码" style="width: 63%"
                @keyup.enter="handleLogin">
                <template #prefix><svg-icon icon-class="validCode" class="el-input__icon input-icon" /></template>
            </el-input>
            <div class="login-code">
                <img :src="codeUrl" @click="getCode" class="login-code-img" />
            </div>
        </el-form-item> -->
<!--        <div style="text-align: left;">-->
<!--          <el-checkbox v-model="loginForm.rememberMe" style="margin:0px 0px 55px 0px;padding-left: 11px;">-->
<!--            <span class="rememberMe">记住密码</span>-->
<!--          </el-checkbox>-->
<!--        </div>-->
        <el-form-item style="width:100%;">
          <el-button :loading="loading" size="large" type="primary" style="width:100%;"
                     class="loginBtn"
                     @click.prevent="handleLogin">
            <span v-if="!loading">登 录</span>
            <span v-else>登 录 中...</span>
          </el-button>
          <div style="float: right;" v-if="register">
            <router-link class="link-type" :to="'/register'">立即注册</router-link>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <!--  底部  -->
    <!-- <div class="el-login-footer">
        <span>Copyright © 2018-2023 ruoyi.vip All Rights Reserved.</span>
    </div> -->
  </div>
</template>

<script setup>
import Cookies from "js-cookie";
import { encrypt, decrypt } from "@/utils/jsencrypt";
import loginUserSrc from '@/assets/images/login-user.png'
import loginPassSrc from '@/assets/images/login-password.png'
import {login} from "@/api";
import {ElMessage} from "element-plus";
import {setLocal} from "@/utils/formExtend";
import {useRouter} from "vue-router";
import {getCurrentInstance, ref} from "vue";
import JSEncrypt from "jsencrypt";

const router = useRouter();
const { proxy } = getCurrentInstance();

const loginForm = ref({
  username: "",
  password: "",
  rememberMe: false,
  code: "",
  uuid: ""
});

const loginRules = {
  username: [{ required: true, trigger: "blur", message: "请输入您的账号" }],
  password: [{ required: true, trigger: "blur", message: "请输入您的密码" }],
  code: [{ required: true, trigger: "change", message: "请输入验证码" }]
};

const codeUrl = ref("");
const loading = ref(false);
// 验证码开关
const captchaEnabled = ref(true);
// 注册开关
const register = ref(false);
const redirect = ref(undefined);

const emits = defineEmits(['isSuccess'])
function handleLogin() {
  proxy.$refs.loginRef.validate(valid => {
    if (valid) {
      loading.value = true;
      // 勾选了需要记住密码设置在 cookie 中设置记住用户名和密码
      if (loginForm.value.rememberMe) {
        Cookies.set("username", loginForm.value.username, { expires: 30 });
        Cookies.set("password", encrypt(loginForm.value.password), { expires: 30 });
        Cookies.set("rememberMe", loginForm.value.rememberMe, { expires: 30 });
      } else {
        // 否则移除
        Cookies.remove("username");
        Cookies.remove("password");
        Cookies.remove("rememberMe");
      }
      const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCk2nvbv1V92I7wodOVGhAezEHrRHsPj3XQRzLEUYzhkXSqTAVDJrC3fS8c8bz2NDIROr5XzOmgTVrDIFL/kYSjc79Q+65684pYMN2R+pZKplB8hlUhJ5LscrES43TquLM6Oycp98D64tqtGm/XIURoi4b9iDQpVFNFqk3O1K919wIDAQAB';
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(publicKey);
      const encName = encrypt.encrypt(loginForm.value.username);
      const encPassword = encrypt.encrypt(loginForm.value.password);
      localStorage.setItem('name',name)
      // 调用action的登录方法
      login({ username: encName, password: encPassword, owner: 'pta' }).then(res => {
        loading.value= false
        ElMessage.success('登录成功')
        const token = res.data.token
        setLocal('token', token, 1000 * 60 * 60)
        // 告诉父元素登录成功了
        emits('isSuccess', 'login')
        //router.push(router.currentRoute.value.query.f)


      }).catch(error => {
        loading.value= false
      })
      // userStore.login(loginForm.value).then(() => {
      //   router.push({ path: redirect.value || "/" });
      // }).catch(() => {
      //   loading.value = false;
      //   // // 重新获取验证码
      //   // if (captchaEnabled.value) {
      //   //     getCode();
      //   // }
      // });
    }
  });
}

function getCookie() {
  const username = Cookies.get("username");
  const password = Cookies.get("password");
  const rememberMe = Cookies.get("rememberMe");
  loginForm.value = {
    username: username === undefined ? loginForm.value.username : username,
    password: password === undefined ? loginForm.value.password : decrypt(password),
    rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
  };
}

// getCode();
getCookie();
</script>

<style lang='postcss' scoped>
.login {
  height: 100vh;
  width: 100%;
  background: url("../assets/images/login-background.png")no-repeat;
  background-size: cover;
  overflow: hidden;
}
.topTitle {
  width: 100%;
  height: 66px;
  text-align: center;
  margin-top: 98px;
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: bold;
  font-size: 50px;
  color: #405AFB;
  font-style: normal;
  text-transform: none;
}

.title {
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: bold;
  font-size: 24px;
  color: #000000;
  text-align: left;
  font-style: normal;
  text-transform: none;
  padding: 0px 0px 0px 11px;
  margin: 0px 0px 50px 0px;
}

.login-form {
  width: 405px;
  height: 425px;
  background: #FFFFFF;
  border-radius: 20px 20px 20px 20px;
  padding: 56px 48px 63px 50px;
  margin-right: 333px;
  margin-top: 164px;
  margin-left: auto;

  .el-input {
    height: 40px;

    ::v-deep(.el-input__wrapper) {
      box-shadow: none;
      border: none;
      border-bottom: 1px solid #D3DAE6;
      border-radius: 0px;
    }
    ::v-deep(.el-input__wrapper:hover) {
      box-shadow: none;
      border: none;
      border-bottom: 1px solid #D3DAE6;
      border-radius: 0px;
    }
    input {
      height: 40px;
    }
  }

  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 0px;
  }

  .rememberMe {
    font-family: Helvetica Neue, Helvetica Neue;
    font-weight: 400;
    font-size: 14px;
    color: #5E6B82;
    line-height: 0px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
  .loginBtn {
    background: linear-gradient( 71deg, #09ADF9 0%, #4650FB 100%);
    border-radius: 23px 23px 23px 23px;
    font-family: Helvetica Neue, Helvetica Neue;
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 0px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
}

.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}

.login-code {
  width: 33%;
  height: 40px;
  float: right;

  img {
    cursor: pointer;
    vertical-align: middle;
  }
}

.el-login-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}

.login-code-img {
  height: 40px;
  padding-left: 12px;
}
</style>
