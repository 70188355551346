import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { ElLoading, ElNotification, ElMessage } from 'element-plus'
import store from "../store";
import actions from '../action';
let loading
// 创建 axios 实例
const request = axios.create({
    baseURL:'/fire',
    headers:{
        'Content-Type': 'application/json;charset=utf-8'
    },
    // API 请求的默认前缀
    timeout: 60000 // 请求超时时间,
})

// 异常拦截处理器
const errorHandler = (error) => {
    loading.close()
    if (error.response.status == 401) {
           // 退出登录
           actions.setGlobalState({ noToken:true,activePath:'/' });
    }
    ElMessage.error(error.response.data.msg)
    return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
    // 系统token
    loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.4)'
    })
    const token = localStorage.getItem("token");
    // 如果 token 存在
    // 让每个请求携带自定义 token 请根据实际情况自行修改
    if (token) {
        config.headers['Authorization'] = JSON.parse(token)
    }
    config.headers['Mark'] = '122'
    return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
    const { data } = response
    // const { getStatus, logout } = useLayoutStore()
    loading.close()
    // 二进制数据则直接返回
    if(response.request.responseType === 'blob' || response.request.responseType === 'arraybuffer') {
        return response
    }
    if(data.code !== 200) {
        let title = '请求失败'
        if(data.code === 401) {
            if (store.state.accessToken) {
                   // 退出登录
                    actions.setGlobalState({ noToken:true,activePath:'/' });
            }
            title = '身份认证失败'
        }
        ElMessage.error(data.msg)
        return Promise.reject(new Error(data.msg || 'Error'))
    }
    return response.data
}, errorHandler)


export default request
