<template>
  <div id="appf">
    <!-- 子组建登录的状况 -->
    <qiankunMenu v-show="isLoginState&&isMenuState" :chooseTenantName="chooseTenantName" :appName="appName"
                 :imgUrl="logoPhoto"
                 :senName="senName" :appNameEnName="appNameEnName" :menuList="menuList" :defaultMenuId="defaultMenuId"
                 :defaultMenuGroup="defaultMenuGroup" @navigatorTo="navigatorTo" @handleOptins="handleOptins"
                 @goHome="goHome">
    </qiankunMenu>
    <!--  子组建 没有登录的状况   -->
    <!-- 原来登录 -->
    <div v-if="!IS_SHANJIAO && !IS_PG">
      <comLogin01 v-show="!isLoginState&&!isRegister&&!isAddInfoDom&&!isRetrievePass&&loginHb" @isSuccess="isSuccess"
                  @register="register" @retrievePassword="retrievePassword"></comLogin01>
      <comLogingPg v-show="!isLoginState&&!isRegister&&!isAddInfoDom&&!isRetrievePass&&loginPG"
                   @isSuccess="isSuccess"></comLogingPg>
    </div>
    <!-- 电网负荷预测系统 Login  -->
    <div v-if="IS_PG">
      <comLogingPg v-show="!isLoginState&&!isRegister&&!isAddInfoDom&&!isRetrievePass&&!announcementViewFlag"
                   @isSuccess="isSuccess"></comLogingPg>
    </div>

    <!-- 山焦登录 -->
    <div v-if="IS_SHANJIAO">
      <com-loginsj v-show="!isLoginState&&!isRegister&&!isAddInfoDom&&!isRetrievePass&&!announcementViewFlag"
                   @isSuccess="isSuccess"></com-loginsj>
    </div>
    <!--  电缆气体的登录 -->
    <comLogincgd v-show="!isLoginState&&!isRegister&&!isAddInfoDom&&!isRetrievePass&&!announcementViewFlag&&loginCGD"
                 @isSuccess="isSuccess"></comLogincgd>



    <!--  子组件已经登录 动态分配菜单   -->
    <div v-show="!isMenuState&&isLoginState">
      <div class="layout-main-navbar1 flex justify-between items-center h-121 shadow-sm overflow-hidden relative z-10">
        <div class="flex items-center px-4 flex-wrap h-121">
          <div class="flex items-center px-4 flex-wrap h-121" v-if="chooseTenantName || appName">
            <img src="./assets/img/icon.png" v-if="!logoPhoto||logoPhoto==='null'" alt=""
                 style="margin-right:10px; width: 40px;">
            <img :src="logoPhoto" v-if="logoPhoto&&logoPhoto!=='null'" alt=""
                 style="margin-right:10px; width: 40px;height: 40px;">
            <div class="layout-sidebar-logo relative" style="line-height: 19px;">
              <span>{{ `${chooseTenantName}` || "" }}</span>
              <p class="f_14">{{ `${senName}` }}</p>
              <p></p>
            </div>
          </div>
        </div>
        <div class="flex items-center px-4 min-width-32">
          <div class="c_fff f_14 m_r_20" v-if="!tenantList.length"> 企业未认证</div>
          <div v-if="tenantList.length">
            <el-dropdown @command='handleOptins($event)'>
              <span class="el-dropdown-link  mr-6 flex flex-center">
                <div class="c_fff">{{ chooseTenantName }}</div>
                <i class="xiala"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click.native="handthem(item)" v-for="(item,index) in tenantList" :key="index">{{
                      item.orgName
                    }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <el-dropdown @command='handleOptins($event)'>
            <span class="el-dropdown-link">
              <el-avatar :size="24"
                         :src="headerImg  ? headerImg : 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'"></el-avatar>
              <el-icon class="el-icon--right">
                <arrow-down/>
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="loginOut">退出</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
      <com-about v-show="!isRegister&&!isAddInfoDom" @isApp="isApp" @closeApp="closeApp" :tenantList="appList"
                 ref="comAbourRef"></com-about>
      <el-empty v-if="!tenantList.length && (pathname==='/'||!pathname)"
                description='请联系客服配置企业信息系统功能 / 客服联系电话：18435178519 / 联系时间：周一至周五  8:00～18:00'/>
      <p class="loginTime  f_14">
        最近登录时间：{{ loginTime }}
      </p>
    </div>
    <!-- 注册 -->
    <register-dom v-if="isRegister" @additionalInfo="additionalInfo"></register-dom>
    <!-- 补充资料 -->
    <add-info-dom v-if="isAddInfoDom" @loginOut="loginOut" @orgCodefun="orgCodefun"></add-info-dom>
    <!--   忘记密码  -->
    <retrievePassword v-if="isRetrievePass"></retrievePassword>
    <!-- 新增公告查看 -->
    <announcement-view v-if="announcementViewFlag"></announcement-view>
  </div>
</template>
<script>
import LayoutTags from '/src/layout/components/tags.vue'
import comLogin from '@/views/login.vue'
import comLoginsj from '@/views/login_SJ.vue'
import comLogincgd from '@/views/login_cgd.vue'
import comLogingPg from '@/views/login_pg.vue'
import comLogin01 from '@/views/User/login01.vue'
import registerDom from '@/views/User/register.vue'
import addInfoDom from '@/views/User/additionalInfo.vue'
import retrievePassword from '@/views/User/retrievePassword.vue'
import qiankunMenu from '@/views/User/qiankunMenu'
import comAbout from '@/views/About.vue'
import {loadMicroApp, registerMicroApps, runAfterFirstMounted, start} from 'qiankun'
import router from "@/router/index"
import actions from './action';
import {validate, setLocal} from './utils/formExtend'
import {
  getMenuPrv,
  getOrgInfoByUser,
  getAppInfo,
  getexchangeForToken,
  getlogoinfo,
  getCurrentUserLoginTime, logout, addUserVisitRecord
} from "@/api";
import {mapMutations, mapState} from "vuex";
import store from "@/store";
import {getUserInfo, getUserInfoByToken} from "@/api/UserInfo";
import {ElMessage} from 'element-plus'
import announcementView from '@/views/announcementView.vue'
// import { setregister, setlogin } from '@/utils/common'

export default {
  name: 'master',
  components: {
    LayoutTags,
    comLogin,
    comAbout,
    comLoginsj,
    comLogin01,
    comLogingPg,
    qiankunMenu,
    registerDom,
    retrievePassword,
    announcementView,
    comLogincgd,
    addInfoDom
  },
  // 其他配置...
  watch: {
    $route(to, from) {
      this.getRouters()
      if (to.fullPath === '/announcementView') {
        this.isRegister = false
        this.isAddInfoDom = false
        this.isRetrievePass = false
        this.isLoginState = false
        this.announcementViewFlag = true
      }

    },
  },
  computed: {
    isShanjiaoEnabled() {
      return process.env.VUE_APP_SHANJIAO === 'true';
    },
    ...mapState([
      "headerImg",
    ]),
  },
  data() {
    return {
      IS_SHANJIAO: process.env.VUE_APP_SHANJIAO === 'true',
      IS_PG: process.env.VUE_APP_PG === 'true',
      loginTime: '',    // 获取首次登录时间
      pathname: '',
      loginSJ: false, // shanjiao
      loginCGD: false, // shanjiao
      loginHb: false, // hebang
      loginPG: false, // hebang
      isLoginState: true,
      isMenuState: false,
      isRegister: false, // 注册
      isAddInfoDom: false, // 补充资料
      isRetrievePass: false, // 忘记密码
      tenantList: [],
      appList: [],
      menuList: [],
      chooseTenant: "", // 选择的租户 默认第一个
      chooseTenantName: "", // 选择的租户 默认第一个
      logoPhoto: "", //logo
      appName: "", // app name
      senName: "", // app name
      enName: "", // app name
      img: '',
      imgUrl: '',
      defaultMenuId: "",
      defaultMenuGroup: [],
      announcementViewFlag: false
    }
  },

  created() {

    let menuList = []
    // 创建vm实例后执行
    // 浏览器控制按钮前进后退触发函数
    // window.addEventListener('popstate', this.popstate, false);
    this.getRouters()
    /*
  *  每次刷新更新一下menuList 的展示 这个的作用在于稳定面包屑
  *  解决bug：  同一个浏览器不同标签打开不同应用，菜单会串标签
  * */
    this.getMentList()

  },

  mounted() {
    // 展示过程
    // 注册一个观察者函数
    actions.onGlobalStateChange((state, preState) => {
      console.log(state,"-------- 传递的消息")
      if (state.noToken) {
        localStorage.removeItem('token')
        localStorage.removeItem('appid')
        localStorage.removeItem('appRow')
        localStorage.removeItem('appName')
        localStorage.removeItem('appNameEnName')
        localStorage.removeItem('chooseTenant')
        localStorage.removeItem('chooseTenantName')
        localStorage.removeItem('imgUrl');
        this.isMenuState = false
        this.isLoginState = localStorage.getItem('token') ? true : false
        let activePath = localStorage.getItem('pathLogin')?localStorage.getItem('pathLogin'):'/'
        this.$router.push({path: activePath})
        this.logoPhoto = ''
        this.getRouters(activePath)
      }
      // else{
      //   this.isLoginState = localStorage.getItem('token')?true:false
      //   this.$router.push({path:"/"})
      //   // this.$router.push({path:this.$router.currentRoute.value.fullPath})
      // }
    })
    this.chooseTenant = localStorage.getItem('chooseTenant')
    this.chooseTenantName = localStorage.getItem('chooseTenantName') || ''
    this.logoPhoto = localStorage.getItem('logoPhoto') || null
    let token = localStorage.getItem('token')
    let appid = localStorage.getItem("appid")
    let appRow = JSON.parse(localStorage.getItem("appRow"))
    this.appName = localStorage.getItem('appName')
    this.appNameEnName = localStorage.getItem('appNameEnName')
    if (token) {
      this.isSuccess()

      //this.getInfo()
    }

  },
  methods: {
    // 查找menuList 在的内容
    async getMentList() {
      let routerList = localStorage.getItem('routerList') ? JSON.parse(localStorage.getItem('routerList')) : []
      if (routerList.length > 0) {
        // let h = await this.getInfoListMenu(routerList)
        let pathname = location.pathname
        let obj = {}
        obj = this.getInfoListMenu(routerList, pathname)
        if (obj && Object.assign(obj) !== '{}') {
          localStorage.setItem('menuList', JSON.stringify(obj))
        } else {
          // 判断是不是存过的路径
          let menuListDefault = localStorage.getItem('menuListDefault') ? JSON.parse(localStorage.getItem('menuListDefault')) : {}
          if (pathname === menuListDefault.path) {
            localStorage.setItem('menuList', JSON.stringify(menuListDefault))
            return
          } else {
            let newObj = {
              "menuId": "98406c8426bd41aa88c211f751acf342",
              "tenantId": null,
              "modelId": null,
              parentName: "--",
              "menuName": "菜单",
              "path": "/disclosureData/disclosureData/disclosureDataAnalysis",
              "descr": null,
              "orderNo": 0,
              "type": null,
              "icon": null,
              "valid": "0",
              "microAppPort": null,
              "poolMenuName": null,
              "isAllow": null,
              "dirId": null,
              "childrenMenuList": null,
              "fid": "0"
            }
            localStorage.setItem('menuList', JSON.stringify(newObj))
          }

        }
      }
    },
    getInfoListMenu(arr, pathname, parent = null) {
      for (let i = 0; i < arr.length; i++) {
        const path = arr[i].path
        const node = arr[i]
        if (pathname === path) {
          node.parentName = parent.menuName
          return node
        }
        if (typeof node === 'object' && node.childrenMenuList && node.childrenMenuList.length > 0) {
          const foundItem = this.getInfoListMenu(arr[i].childrenMenuList, pathname, arr[i]);
          if (foundItem) {
            return foundItem;
          }
        }
      }
      return null;
    },
    // 获取路由
    getRouters(activePath) {
      this.isLoginState = localStorage.getItem('token') ? true : false
      this.pathname = activePath ? activePath : location.pathname
      let pathname = activePath ? activePath : location.pathname
      this.isRegister = false
      this.isRetrievePass = false
      this.isAddInfoDom = false
      if (pathname === '/register') {
        this.isRegister = true
      }
      if (pathname === '/additionInfo') {
        this.isAddInfoDom = true
      }
      if (pathname === '/retrievePassword') {
        this.isRetrievePass = true
      }
      if (pathname === '/loginSJ' || pathname === '/loginsj') {
        this.loginSJ = true
      }
      if (pathname === '/loginCGD' || pathname === '/logincgd') {
        this.loginCGD = true
      }
      if (pathname === '/loginPg' || pathname === '/loginpg') {
        this.loginPG = true
        document.title = '综合管理系统'
      }
      if (pathname === '/loginHb' || pathname === '/loginhb' || pathname === '/') {
        this.loginHb = true
      }
      if (pathname === '/announcementView') {
        this.announcementViewFlag = true
      } else {
        this.announcementViewFlag = false
      }
      //window.addEventListener('storage', this.handleStorageChange);
      this.defaultMenuId = store.state.menuId
      this.defaultMenuGroup = store.state.menuGroup
    },
    resetData() {
      // 重置数据为初始值
      this.loginTime = '';
      this.pathname = '';
      this.loginSJ = false;
      this.loginHb = false;
      this.loginCGD = false;
      this.isLoginState = true;
      this.isMenuState = false;
      this.isRegister = false;
      this.isAddInfoDom = false
      this.isRetrievePass = false;
      this.tenantList = [];
      this.appList = [];
      this.menuList = [];
      this.chooseTenant = "";
      this.chooseTenantName = "";
      this.logoPhoto = "";
      this.appName = "";
      this.senName = "";
      this.enName = "";
      this.img = '';
      this.imgUrl = '';
      this.defaultMenuId = "";
      this.defaultMenuGroup = [];
      this.announcementViewFlag = false;
    },
    getCurrentUserLoginTimeApi() {
      getCurrentUserLoginTime().then(res => {
        this.loginTime = res.data
      })
    },
    // getInfo() {
    //   //localStorage.getItem('name')
    //   getUserInfo({ username: localStorage.getItem('name') }).then(res => {
    //     let arr = []
    //     let arrName = []

    //     this.userAvatar = res.data.sysUser.photo ? process.env.VUE_APP_URL_IMG + res.data.sysUser.photo : ''
    //   })
    // },
    ...mapMutations(['SET_MENUID', 'SET_MENUGROUP', 'SET_HEADERIMG']),
    // 回到主页
    goHome() {
      localStorage.removeItem('appid')
      localStorage.removeItem('imgUrl')
      localStorage.removeItem('appName')
      localStorage.removeItem('appNameEnName')
      this.isMenuState = false
      this.appName = ''
      this.appNameEnName = ''
      this.imgUrl = ''
      this.$router.push('/')
    },
    //用户切换
    handthem(val) {
      this.chooseTenant = val.orgCode
      this.chooseTenantName = val.orgName
      document.title = this.chooseTenantName  // 设置页面标题
      this.logoPhoto = val.photoTemp ? val.photoTemp : null
      this.senName = val.senName
      localStorage.setItem('chooseTenant', val.orgCode)
      localStorage.setItem('chooseTenantName', val.orgName)
      localStorage.setItem('orgFullName', val.orgFullName)
      // if (val.photo) {
      //   console.log("12312")
      localStorage.setItem('logoPhoto', this.logoPhoto)
      // } else {
      //   localStorage.removeItem('logoPhoto')
      // }
      // 获取应用列表信息
      this.getNewToken(val)
    },
    getNewToken(val) {
      getAppInfo(this.chooseTenant).then(res => {
        let data = res.data.map(obj => ({...obj, show: false}));
        this.appList = data
        getexchangeForToken(val.orgCode).then(item => {
          const token = item.data.token
          setLocal('token', token, 1000 * 60 * 60)
          /* 新增一个逻辑，如果列表只有一个直接跳进去  */
          if (this.appList.length === 1) {
            this.$nextTick(() => {
              this.$refs.comAbourRef.chooseApp(this.appList[0])
            })
          }
        })
      }).catch((err) => {
        this.appList = []
      })
    },
    /*前往注册*/
    register() {
      this.isRegister = true
    },
    /*前往忘记密码*/
    retrievePassword() {

      this.isRetrievePass = true
    },
    /*前往补充资料*/
    additionalInfo() {
      this.getRouters('/additionInfo')
    },
    /* 刷新资料 */
    orgCodefun() {
      this.getOrgInfoByUserApi()
    },
    async isSuccess(type = '') {
      // 记录 登录的地址信息
      if (type === 'login') {
        let pathLogin = location.pathname
        localStorage.setItem('pathLogin', pathLogin)
      }
      await this.getRouters()

      this.isLoginState = true
      this.tenantList = []
      if (this.isLoginState) {
        // 获取用户信息
        const getInfoResult = await this.getInfo();
        if (getInfoResult.code === 200) {
          await this.getCurrentUserLoginTimeApi()
          // 获取用户组织信息
          await this.getOrgInfoByUserApi()
        }
      }
    },
    /* 调用获取租户列表的list */
    getOrgInfoByUserApi() {
      getOrgInfoByUser().then(res => {
        /*
      *  获取组织信息 -- 如果组织不存在
      *  补充资料从组织下手
      * */
        if (!res.data.length) {
          this.$router.push({path: "/additionInfo", query: {active: 2}})
          return
        }
        if (res.code == 200 && res.data.length) {
          let tenantList = res.data || []
          this.tenantList = tenantList

          this.chooseTenant = localStorage.getItem('chooseTenant') ? localStorage.getItem('chooseTenant') : this.tenantList[0].orgCode
          this.chooseTenantName = localStorage.getItem('chooseTenantName') ? localStorage.getItem('chooseTenantName') : this.tenantList[0].orgName
          document.title = this.chooseTenantName  // 设置页面标题
          this.senName = this.tenantList[0].senName
          let orgFullName = localStorage.getItem('orgFullName') ? localStorage.getItem('orgFullName') : this.tenantList[0].orgFullName
          localStorage.setItem('orgFullName', orgFullName)
          localStorage.setItem('chooseTenant', this.chooseTenant)
          localStorage.setItem('chooseTenantName', this.chooseTenantName)
          let photo = this.tenantList[0].photoTemp ? this.tenantList[0].photoTemp : null
          this.logoPhoto = localStorage.getItem('logoPhoto') ? localStorage.getItem('logoPhoto') : photo
          // 获取应用列表信息
          this.getAppInfoApi()
          // getlogoinfo().then(res=>{
          //   this.imgUrl=res.data.url
          // })
          // 缓存内添加一个appid
          let appid = localStorage.getItem("appid")
          let appRow = JSON.parse(localStorage.getItem("appRow"))
          if (appid) {
            this.isMenuState = true
            this.isApp(appRow)
          }
        } else {
          //  企业未认证
          this.tenantList = []
          this.appList = []
        }

      }).catch(err => {
        // 401 不直接调用接口退出   要清除缓存
        this.handleOptins('401')
      })
    },
    getInfo() {
      // 根据 根据token获取用户名
      return getUserInfoByToken().then(r => {
        if (r.code === 200) {
          return getUserInfo({username: r.data.userName}).then(res => {
            let imageUrl = res.data.sysUser.photoTemp ? res.data.sysUser.photoTemp : '';
            this.SET_HEADERIMG(imageUrl);
            return {code: 200};
          });
        } else {
          return {code: r.code};
        }
      }).catch((err) => {
        // 401 不直接调用接口退出   要清除缓存
        this.handleOptins('401')
        return {code: 500}; // 其他错误
      });
    },
    // getInfo() {
    //   // 根据 根据token获取用户名
    //   getUserInfoByToken().then(r => {
    //     if (r.code === 200) {
    //       getUserInfo({username: r.data.userName}).then(res => {
    //         let imageUrl = res.data.sysUser.photoTemp ? res.data.sysUser.photoTemp : ''
    //         this.SET_HEADERIMG(imageUrl)
    //       })
    //     }
    //   }).catch((err)=>{
    //
    //   })
    //
    // },
    // 获取应用列表信息
    getAppInfoApi() {
      getAppInfo(this.chooseTenant).then(res => {
        this.appList = res.data || []
        /*
             *  获取应用信息，如果应用不存在直接添加应用
             * */
        if (!this.appList.length) {
          this.$router.push({path: "/additionInfo", query: {active: 3}})
          return
        }
        /* 新增一个逻辑，如果列表只有一个直接跳进去  */
        if (this.appList.length === 1) {
          this.$nextTick(() => {
            this.$refs.comAbourRef.chooseApp(this.appList[0])
          })
        }
      })
    },
    // 关闭app
    closeApp() {
      this.appList.forEach(item => {
        item.show = false
      })
    },
    // 选择app
    isApp(row) {
      localStorage.setItem('appid', row.appId)
      localStorage.setItem('appRow', JSON.stringify(row))
      localStorage.setItem('imgUrl', location.origin + process.env.VUE_APP_URL_IMG + row.path)
      localStorage.setItem('appName', row.sname)
      localStorage.setItem('appNameEnName', row.enName)
      this.appNameEnName = row.enName
      this.appName = row.sname
      this.imgUrl = location.origin + process.env.VUE_APP_URL_IMG + row.path
      // 获取这个app的菜单权限 并进行动态渲染
      getMenuPrv(row.appId).then(res => {
        this.isMenuState = true
        let appList = []
        this.menuList = res.data
        localStorage.setItem('routerList', JSON.stringify(res.data))
        //sessionStorage.setItem("menuItem",JSON.stringify(res.data))
        //  如果页面的 路由为空的时候 默认跳转到第一菜单的路由上
        let fullPath = this.$router.currentRoute.value.fullPath
        if (this.$router.currentRoute.value.fullPath === '/' || fullPath.indexOf('login') > -1) {
          let to = ''
          if (this.menuList[0].childrenMenuList !== null && this.menuList[0].childrenMenuList.length) {
            to = this.menuList[0].childrenMenuList[0].path
            localStorage.setItem('menuList', JSON.stringify(this.menuList[0].childrenMenuList[0]))
            this.defaultMenuId = '0,0'
            this.defaultMenuGroup = [0]
            this.SET_MENUID('0,0');
            this.SET_MENUGROUP([0]);
          } else {
            to = this.menuList[0].path
            localStorage.setItem('menuList', JSON.stringify(this.menuList[0]))
            this.defaultMenuId = '0'
            this.defaultMenuGroup = [0]
            this.SET_MENUID('0');
            this.SET_MENUGROUP([0]);
          }
          this.$router.push(to)
        }

        this.menuList.forEach((item) => {
          if (item.childrenMenuList !== null && item.childrenMenuList.length) {
            item.childrenMenuList.forEach(val => {
              val.parentName = item.menuName
            })
          } else {
            item.parentName = item.menuName
          }

        })
        if (this.$router.currentRoute.value.fullPath === '/') {
          if (this.menuList[0].childrenMenuList !== null) {
            localStorage.setItem('menuList', JSON.stringify(this.menuList[0].childrenMenuList[0]))
          } else {
            localStorage.setItem('menuList', JSON.stringify(this.menuList[0]))
          }
        } else {
          // if(this.menuList[0].childrenMenuList!==null){
          //     localStorage.setItem('menuList', JSON.stringify(this.menuList[0].childrenMenuList[0]))
          // }else{
          //     localStorage.setItem('menuList', JSON.stringify(this.menuList[0]))
          // }
        }
        this.$forceUpdate()

        // this.menuList.forEach((item)=>{
        //   let obj ={
        //     name: (item.path).substring(1),
        //         entry: process.env.VUE_APP_URL+':'+item.microAppPort,
        //       container: '#subapp-viewport',
        //       activeRule: item.path,
        //       props:{
        //       actions,
        //         routerBase: item.path, // 子应用的路由前缀(router的base)
        //         routerList: [], // 子应用的路由列表
        //   },
        //   }
        //   appList.push(obj)
        // })
        // 卸载全部子应用

        // 获取所有已加载的子应用程序实例
        //const apps = window.__POWERED_BY_QIANKUN__ || [];
        // // 遍历所有子应用程序实例，调用卸载方法
        // apps.forEach((app) => {
        //   app?.instance?.unmount();
        // });
        // console.log(appList)
        // appList.forEach(element => {
        //   loadMicroApp(element);
        // });
      })
    },
    loginOut() {
      this.handleOptins("401")
    },
    handleOptins(text) {
      if (text === 'loginOut') {
        // 调用接口
        logout().then(res => {
          // 退出登录
          localStorage.removeItem('appid')
          // 退出登录去掉新增测算记录信息
          localStorage.removeItem('createCalculationForm');
          localStorage.removeItem('createCalculationTable');
          localStorage.removeItem('orgFullName');
          localStorage.removeItem('logoPhoto');
          localStorage.removeItem('userPath');
          localStorage.removeItem('menuList');
          localStorage.removeItem('name');
          localStorage.removeItem('imgUrl');
          this.resetData()
          let activePath = localStorage.getItem('pathLogin')
          actions.setGlobalState({noToken: true, activePath: activePath});
        })

      }
      if (text === '401') {
        //  退出登录 不调用接口
        localStorage.removeItem('appid')
        // 退出登录去掉新增测算记录信息
        localStorage.removeItem('createCalculationForm');
        localStorage.removeItem('createCalculationTable');
        localStorage.removeItem('orgFullName');
        localStorage.removeItem('logoPhoto');
        localStorage.removeItem('userPath');
        localStorage.removeItem('menuList');
        localStorage.removeItem('name');
        localStorage.removeItem('imgUrl');
        this.resetData()
        actions.setGlobalState({noToken: true, activePath: '/'});
        this.getRouters()
      }
      if (text === 'userInfo') {
        // 记录跳转个人中心前页面的path
        let path = location.pathname + location.search
        localStorage.setItem('userPath', path)
        // 用户中心
        this.$router.push('/userInfo')
      }
    },
    navigatorTo(row, id, groupId) {
      let url = row.path
      this.SET_MENUID(id);
      this.SET_MENUGROUP([groupId]);
      let token = localStorage.getItem('token')
      localStorage.setItem('menuList', JSON.stringify(row))
      let to = row.path
      if (!token) {
        // 没有登录通知主应用
        actions.setGlobalState({noToken: true, activePath: to});
        return
      } else {
        /* 调用接口添加访问记录 */
        let params = {
          url: to,
          orgCode: localStorage.getItem("chooseTenant") || ""
        }
        addUserVisitRecord(params).then(res => {
          console.log("访问记录添加成功", to)
        })
        if (to.indexOf('cgd') > -1) {
          this.$router.replace(to)
        } else {
          this.$router.push(to)
        }
        //  this.$router.push(to)
        // console.log(window.history)
        // // this.$nextTick(()=>{
        // //
        // // })
        // //

        /* 方法2  可以解决 但是不丝滑*/
        // history.pushState(null, url, url)
        // location.reload();
        /* 方法1  可以解决 但是不丝滑*/
        //  //window.top.location.href = url;  可以解决 但是不丝滑
      }
    },
    initQiankun() {
      registerMicroApps(
          {
            beforeLoad: [
              app => {
                // eslint-disable-next-line no-console
                console.log('before load', app)
              }
            ],
            beforeMount: [
              app => {
                // eslint-disable-next-line no-console
                console.log('before mount', app)
              }
            ],
            afterUnmount: [
              app => {
                // eslint-disable-next-line no-console
                console.log('after unload', app)
              }
            ]
          }
      )
      runAfterFirstMounted(() => {
        // eslint-disable-next-line no-console
        console.info('first app mounted')
      })
      start({prefetch: true})
    }
  },
  destroyed() {
  },
}
</script>

<style lang="postcss" scoped>
::v-deep(.el-empty__description p) {
  margin-top: 30px;
}

.el-menu-item.is-active {
  color: var(--el-menu-active-color) !important;
}

.f_14 {
  font-size: 14px;
}

.el-menu-item.is-active {
  background-color: #EAF4FF !important;
  color: #1890FF !important;
}

.el-menu-item:hover {
  background-color: #EAF4FF !important;
}

.el-loading-spinner .el-loading-text {
  text-align: center;
}

.top_header {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.xiala {
  display: inline-block;
  height: 20px;
  width: 20px;
  background: url(./assets/img/xiala.png) center no-repeat;
}

.top_header .logo {
  font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
  font-weight: 650;
  font-style: normal;
  color: #333;
  font-size: 18px;
  margin: 0 20px;
}

.top_header .text {
  font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
  font-weight: 650;
  font-style: normal;
  color: #FFFFFF;
  font-size: 18px;
}

#subapp-viewport {
  height: 100%;
  width: 100%;

  &
  > div {
    height: 100%;
    width: 100%;
  }

}

#appf {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  width: 100vw;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &
    .router-link-exact-active {
      color: #42b983;
    }

  }
}

.h-121 {
  height: 64px !important;
}

.w-64 {
  width: 256px !important;
}

.w-64 {
  width: 256px !important;
}

.layout-main-navbar1 {
  background: url(./assets/img/bg_nav.png);
  background-color: #1890FF;
  color: #fff;
}

.c_fff {
  color: #fff;
  font-weight: bold;
}


.layoutSidebar_style {
  height: calc(100vh - 64px);
}

.classTestwyc {
  display: inline-block;
  padding: 10px 20px;
  width: 88px;
  height: 76px;
}

.classTestwyc .el-dropdown__list {
  height: 100%;
}

.classTestwyc .el-dropdown-menu {
  height: 100%;
  width: 100%;
}

.classTestwyc .el-dropdown-menu .el-dropdown-menu__item {
  /* height: 100%; */
  width: 100%;
  line-height: 22px;
  padding-left: 0px;
  padding-right: 0px;
  text-align: center;
  margin: 0px;
  display: block;
  padding-bottom: 6px;
}

.loginTime {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #666
}

</style>
