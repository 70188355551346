/**
 * 校验邮箱
 * @param emailStr{String}
 * @returns {boolean}
 */
export const checkEmail = (emailStr) => /^[a-z0-9]{1}[a-z0-9_-]{1,}@[a-z0-9]{1,}(\.[a-z]{2,})*\.[a-z]{2,}$/.test(emailStr)
/**
 * 校验密码及强度约束
 * @param password{String}
 * @returns {boolean}
 */
export const checkPassword = (password) => /(?=^.{8,16}$)(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*]).*$/.test(password)
/**
 * 校验手机号
 * @param phoneNum{String}
 * @returns {boolean}
 */
export const checkPhone = (phoneNum) => /^1[345678]{1}\d{9}$/.test(phoneNum)
export function setregister (account) { 
    try { 
      console.log(account) 
      window.TDAPP.register(account) 
    //   return true 
      setlogin (account)
    } catch (err) { 
      console.log('=>err', err) 
      return false 
    } 
  }
  export function setlogin (account) { 
    try { 
      console.log(account, window.TDAPP) 
      window.TDAPP.login(account) 
      return true 
    } catch (err) { 
      console.log('=>err', err) 
      setregister(account)
    //   return false 
    } 
  }
