import request from '@/utils/request'

/* 获取用户信息 */
export function getUserInfo(data) {
    return request({
        url: '/api/app/app/user/getUserInfo',
        method: 'post',
        data:data
    })
}
/* 根据token获取用户名 */
export function getUserInfoByToken(data) {
    return request({
        url: '/api/app/app/user/getUserInfoByToken',
        method: 'post',
        data:data
    })
}

/* 修改密码 */
export function changePassword(data) {
    return request({
        url: '/api/app/app/user/changePassword',
        method: 'post',
        data:data
    })
}


//用户中心-更新用户信息
export function updatePersonUserInfo(data) {
    return request({
        url: '/api/app/app/user/updatePersonUserInfo',
        method: 'post',
        data:data
    })
}


// 获取验证码
export function getCodeApi(data) {
    return request({
        url: '/api/app/getCode',
        method: 'post',
        data:data
    })
}

//注册
export function register(data) {
    return request({
        url: '/api/app/register',
        method: 'post',
        data:data
    })
}

// 验证用户名username
export function checkUsername(data) {
    return request({
        url: '/api/app/checkUsername',
        method: 'get',
        params:data
    })
}

//手机验证码验证

export function verifyMessageCode(data) {
    return request({
        url: '/api/app/verifyMessageCode',
        method: 'get',
        params:data
    })
}


// 获取验证码
export function getCode(data) {
    return request({
        url: '/api/app/getCode',
        method: 'post',
        data:data
    })
}


//修改密码（忘记密码）
export function changePasswordFort(data) {
    return request({
        url: '/api/app/changePassword',
        method: 'post',
        data:data
    })
}



// 注册第一步验证用户名
export function isUserExist(data) {
    return request({
        url: '/api/app/isUserExist',
        method: 'get',
        params:data
    })
}
// 注册第二步骤 获取验证码
export function registerOutPre(data) {
    return request({
        url: '/api/app/registerOutPre',
        method: 'get',
        params:data
    })
}
// 注册第三部注册
export function registerOut(data) {
    return request({
        url: '/api/app/registerOut',
        method: 'post',
        data:data
    })
}
