import request from '@/utils/request'

const api = {
    login: '/api/app/login',
    getMenuPrv: '/api/app/app/user/getMenuPrv',
    getOrgInfoByUser: "/api/app/app/organization/getOrgInfoByUser",
    getAppInfo: "/api/app/app/appInfo/getAppInfo",
    exchangeForToken: '/api/app/exchangeForToken',
    getlogoinfo: '/api/business/logo/info'
}

// 登录
export function login(param) {
    return request({
        url: api.login,
        method: 'post',
        headers: {
            Mark: 'applicationPlatform'
        },

        data: param
    })
}

export function logout() {
    return request({
        url: '/api/app/logout',
        method: 'get',
    })
}

// 根据用户获取用户租户
export function getOrgInfoByUser() {
    return request({
        url: api.getOrgInfoByUser,
        method: 'get',

    })
}

// 获取用户最近登录时间
export function getCurrentUserLoginTime() {
    return request({
        url: '/api/app/getCurrentUserLoginTime',
        method: 'get',
    })
}

// 根据用户租户获取用户菜单
export function getAppInfo(param) {
    return request({
        url: api.getAppInfo + '?orgCode=' + param,
        method: 'get',
    })
}

// 获取应用权限
export function getMenuPrv(appId) {
    return request({
        url: api.getMenuPrv,
        method: 'post',
        headers: {
            appId: appId
        },
    })
}

// 获取新的token
export function getexchangeForToken(name) {
    return request({
        url: api.exchangeForToken,
        method: 'get',
        params: {
            orgCode: name
        },
    })
}

//获取新的logo
export function getlogoinfo() {
    return request({
        url: api.getlogoinfo,
        method: 'get',
    })
}

//关联注册用户和组织
export function addOrgUser(data) {
    return request({
        url: '/api/app/app/user/addOrgUser',
        method: 'post',
        data: data
    })
}

// 查询所有二级标志特点
export function getAllFeatures(data) {
    return request({
        url: '/api/app/app/user/getAllFeatures',
        method: 'get',
    })
}

// 根据企业类型查询所属的应用
export function selectAppByFeature(markCode) {
    return request({
        url: '/api/app/app/user/selectAppByFeature/' + markCode,
        method: 'get',
    })
}

// 关联组织和选中应用
export function addOrgApp(data) {
    return request({
        url: '/api/app/app/user/addOrgApp',
        method: 'post',
        data:data
    })
}

/* 添加访问记录 */
export function addUserVisitRecord(data) {
    return request({
        url: `/api/app/addUserVisitRecord`,
        method: 'post',
        data:data
    })
}
