 <template>
  <div class="loginbj flex flex-center w-screen h-screen">
    <div class="loginContent ">
      <div class="leftfont ">
        <p class="fonts">电力辅助交易决策平台</p>
      </div>
      <div class="rightContent">
        <div class="title">登录</div>
        <el-form ref='ruleForm' label-position='right' :model='form' :rules='rules'>
          <el-form-item class='forit forit02 flex' prop='name'>
            <el-input v-model='form.name' class="userinp" placeholder='请输入用户名' prefix-icon='el-icon-user' />
          </el-form-item>
          <el-form-item class='forit forit02 flex ' prop='pwd' style="margin-bottom: 0px;">
            <el-input v-model='form.pwd' show-password  class="userinp" placeholder='请输入密码' prefix-icon='el-icon-user' />
          </el-form-item>
          <el-form-item prop='checked' class='f'>
            <div style='width: 100%;' class='flex justify-between mt-2'>
              <span style='color:#B3B3B3' @click='forget'>忘记密码？</span>
              <span class='span_primary' @click='register'>用户注册</span>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' class='subm' @click='onSubmit'>登录</el-button>
          </el-form-item>

        </el-form>
      </div>
    </div>
  </div>

</template>

<script setup>
import {defineComponent, reactive, ref} from 'vue'
import {ElMessage} from 'element-plus'
import {validate, setLocal} from '../utils/formExtend'
import {mapMutations} from 'vuex'
import router from '@/router/index'
import { login} from '@/api/index'
import '@/registerMicroAppsConfig'
import {defineEmits} from 'vue'
import JSEncrypt from 'jsencrypt';

// 忘记密码
const forget = () => {
  ElMessage.info('该功能正在努力研发中...')
}
// 注册
const register = () => {
  ElMessage.info('该功能正在努力研发中...')
}


  const emit = defineEmits(['isSuccess'])
  const storeMutation = mapMutations(['SET_ACCESSTOKEN']);
  let form = reactive({
    name: '',
    pwd: '',
    owner: 'pta'
  })
  const loginApi =  (param) => {
    login(param).then(res=>{
     ElMessage.success('登录成功')
      sessionStorage.setItem('loginUserName',param.name)
      const token = res.data.token
      setLocal('token', token, 1000 * 60 * 60)
      // 告诉父元素登录成功了
       emit('isSuccess',true)
      //router.push(router.currentRoute.value.query.f)
    }).catch(error=>{

    })

  }
  const ruleForm = ref(null)
  const enterSubmit = (e) => {
    if (e.key === 'Enter') {
      onSubmit()
    }
  }
  const onSubmit = async () => {
    let {name, pwd} = form
    if (!await validate(ruleForm)) return
    const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCk2nvbv1V92I7wodOVGhAezEHrRHsPj3XQRzLEUYzhkXSqTAVDJrC3fS8c8bz2NDIROr5XzOmgTVrDIFL/kYSjc79Q+65684pYMN2R+pZKplB8hlUhJ5LscrES43TquLM6Oycp98D64tqtGm/XIURoi4b9iDQpVFNFqk3O1K919wIDAQAB';
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    const encName = encrypt.encrypt(name);
    const encPassword = encrypt.encrypt(pwd);
    await loginApi({username: encName, password: encPassword, owner: 'pta'})
    // await loginApi({username: name, password: pwd, owner: 'pta'})

  }
  const rules = reactive({
    name: [
      {
        validator: (rule, value, callback) => {
          if (!value) {
            return callback(new Error('用户名不能为空'))
          }
          callback()
        }, trigger: 'blur'
      }
    ],
    pwd: [
      {
        validator: (rule, value, callback) => {
          if (!value) {
            return callback(new Error('密码不能为空'))
          }
          callback()
        }, trigger: 'blur'
      }
    ]
  })

</script>

<style lang='less' scoped>
.flex-center{
  align-items: center;
  justify-content: center;
}
.loginbj{
  width: 100%;
  height: 100vh;
  background-image: url('../assets/img/loginbj.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
.loginContent{
  width: 1424px;
  height: 693px;
  background: url('../assets/img/loginbj2.png') center no-repeat;
  background-size: 100% 100%;
  //padding-top: 133px;
  box-sizing: border-box;
  display: flex;
.leftfont{
  display: flex;
  width: 50%;
  font-size: 36px;
  font-family: YouSheBiaoTiHei;
  font-weight: 400;
  color: #FFFFFF;
  //margin-top: 155px;
  align-items: center;
  justify-content: center;
.fonts{
  letter-spacing: 10px;
  text-align: center;

}
}
.rightContent{
  width: 50%;
  text-align: center;
  padding-top:120px;
.title{
  font-size: 32px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #1890FF;
  margin-bottom: 36px;
  text-align: center;
}
  .f{
    width: 495px;
    padding: 0 10px 0 10px;
    margin: 0 auto 10px;
  }
.forit{
  width: 495px;
  height: 79px;
  padding: 0;
  box-sizing: border-box;
  margin: 0 auto 29px;
  border: 1px solid #DCDCDC;
  border-radius: 10px;
.el-input{
  width: 100%;
  height: 100%;
}
  ::v-deep(.el-input__inner){
  width: 100%;
  height: 100%;
  border: none !important;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 300;
  color: #B3B3B3;
}
  ::v-deep(.el-form-item__error){
  top: 56px;
  left: 20px;
}
  ::v-deep(.el-input__wrapper){
    box-shadow:none;
    width: 100%;
    height: 100%;
    border: none;
}
.userinp{
  width: 100%;
  height: 90%;
}
.userinp01{
  width: 70%;
  height: 100%;
}
.code{
  width: 30%;
  text-align: right;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #3381FF;
  line-height: 79px;
  cursor: pointer;
  letter-spacing: 1px;
}
}
.forit02{
  ::v-deep(.el-form-item__error){
  top: 83px;
  left: 20px;
}
}
.subm{
  width: 389px;
  height: 63px;
  background: linear-gradient(90deg, #6FB2FF, #095FFF);
  box-shadow: 0px 7px 27px 0px rgba(50,129,255,0.51);
  border-radius: 10px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 63px;
  margin: 0 auto 0;
  text-align: center;
  letter-spacing: 30px;
  text-indent: 30px;
  display: flex;
  justify-content: center;
}
.checkContent{
  width: 495px;
  margin: 70px auto 30px;
  text-align: left;
}
}
}
}

</style>
