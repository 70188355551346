<template>
  <div class="home" id="home">
    <div class="layout-main-navbar1 flex justify-between items-center h-121 shadow-sm overflow-hidden relative z-10"
         data-v-13877386="">
      <div class="flex items-center px-4 flex-wrap h-121" data-v-2077725d="">
        <!--        <el-icon class='text-2xl cursor-pointer h-12 leading-12' @click='changeCollapsed'>-->
        <!--            <el-icon-expand v-if='getMenubar.status' />--><!--            <el-icon-fold v-else />-->
        <!--        </el-icon>-->
        <div class="layout-sidebar-logo relative" data-v-2077725d="" style="line-height: 19px;"><span
            class="pl-2" data-v-2077725d="">电力交易辅助决策平台</span>
          <p class="f_14" data-v-2077725d="">Power trading auxiliary decision-making platform</p>
          <p data-v-2077725d=""></p>
        </div>
      </div>
      <div class="flex items-center flex-row-reverse px-4 min-width-32" data-v-2077725d=""><!-- 用户下拉 -->
        <div class="el-dropdown" data-v-2077725d="">
          <div class="el-dropdown--default el-tooltip__trigger el-tooltip__trigger"><span
              class="el-dropdown-link flex flex-center px-2" data-v-2077725d=""><span
              class="el-avatar el-avatar--circle" data-v-2077725d=""
              style="--el-avatar-size:30px;"><img
              src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
              style="object-fit: cover;"></span><span class="ml-2"
                                                      data-v-2077725d=""></span><i class="el-icon" data-v-2077725d=""><svg
              class="icon"
              width="200" height="200" viewBox="0 0 1024 1024"
              xmlns="http://www.w3.org/2000/svg" data-v-2077725d="">
                                        <path fill="currentColor"
                                              d="M831.872 340.864L512 652.672 192.128 340.864a30.592 30.592 0 00-42.752 0 29.12 29.12 0 000 41.6L489.664 714.24a32 32 0 0044.672 0l340.288-331.712a29.12 29.12 0 000-41.728 30.592 30.592 0 00-42.752 0z">
                                        </path>
                                    </svg></i></span></div><!--teleport start--><!--teleport end--><!--v-if-->
        </div>
        <div class="hidden-xs-only px-2" data-v-2077725d="">
          <svg class="svg-icon cursor-pointer"
               aria-hidden="true" data-v-10ac6507="">
            <use xlink:href="#icon-svg-fullscreen" data-v-10ac6507=""></use>
          </svg><!-- 切换失效 -->
          <!-- <svg-icon class-name='cursor-pointer' :icon-class='isFullscreen ? "svg-exit-fullscreen" : "svg-fullscreen"' @click='changeScreenfull' /> -->
        </div><!-- 换肤 --><!--teleport start-->
        <div class="el-overlay" style="z-index: 2002; display: none;">
          <div aria-modal="true" aria-labelledby="el-drawer__title" aria-label="更换主题"
               class="el-drawer rtl" role="dialog" style="width: 30%;">
            <header id="el-drawer__title" class="el-drawer__header"><span role="heading"
                                                                          title="更换主题">更换主题</span>
              <button aria-label="close 更换主题" class="el-drawer__close-btn"
                      type="button"><i class="el-icon el-drawer__close">
                <svg class="icon" width="200"
                     height="200" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                  <path fill="currentColor"
                        d="M764.288 214.592L512 466.88 259.712 214.592a31.936 31.936 0 00-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1045.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0045.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 10-45.12-45.184z">
                  </path>
                </svg>
              </i></button>
            </header><!--v-if--><!--v-if-->
          </div>
        </div><!--teleport end-->
      </div>
    </div>
    <div class='layout flex '>
      <div class='layout-sidebar flex flex-col layoutSidebar_style transition-width duration-200 shadow w-64'>
        <div class='layout-sidebar-menubar flex flex-1 overflow-hidden'>
          <el-scrollbar wrap-class='scrollbar-wrapper'>
            <el-menu mode='vertical' class="w-64" :collapse-transition='false'>
              <el-menu-item index="1" key="/" @click="navigatorTo('/')">Home</el-menu-item>
              <el-sub-menu index="2">
                <template #title>
                  企业搜索
                </template>
                <el-menu-item-group>
                  <el-menu-item index="2-4-1"
                                @click="navigatorTo('/enterpriseSearch/enterpriseSearch/list')">企业搜索
                  </el-menu-item>
                  <el-menu-item index="2-4-2"
                                @click="navigatorTo('/enterpriseSearch/enterpriseSearch/followList')">关注列表
                  </el-menu-item>
                  <el-menu-item index="2-4-3"
                                @click="navigatorTo('/enterpriseSearch/EnterpriseMap/map')">地图拓客
                  </el-menu-item>
                </el-menu-item-group>
              </el-sub-menu>
              <el-sub-menu index="3">
                <template #title>
                  用能检测
                </template>
                <el-menu-item-group>
                  <el-menu-item index="3-4-1"
                                @click="navigatorTo('/energyTesting/energyTesting/comprehensive')">综合用能
                  </el-menu-item>
                  <el-menu-item index="3-4-2"
                                @click="navigatorTo('/energyTesting/energyTesting/monitor')">用能监测
                  </el-menu-item>
                  <el-menu-item index="3-4-3"
                                @click="navigatorTo('/energyTesting/energyTesting/forecast')">用能预测
                  </el-menu-item>
                  <el-menu-item index="3-4-4"
                                @click="navigatorTo('/energyTesting/energyTesting/treeManage')">用能环节管理
                  </el-menu-item>
                  <el-menu-item index="3-4-5"
                                @click="navigatorTo('/energyTesting/energyTesting/treeManageDetails')">用能环节管理详情
                  </el-menu-item>
                </el-menu-item-group>
              </el-sub-menu>
              <el-sub-menu index="4">
                <template #title>
                  合同管理
                </template>
                <el-menu-item-group>
                  <el-menu-item index="4-4-1"
                                @click="navigatorTo('/holdKanban/holdKanban/powerManage/powerPurchaseManagement')">购电管理
                  </el-menu-item>
                  <el-sub-menu index="4-4-2">
                    <template #title>中长期交易管理</template>
                    <el-menu-item index="4-4-2-1"
                                  @click="navigatorTo('/holdKanban/holdKanban/transactionManagement/contractManagement')">
                      合同管理
                    </el-menu-item>
                    <el-menu-item index="4-4-2-2"
                                  @click="navigatorTo('/holdKanban/holdKanban/transactionManagement/centralizedBidding')">
                      集中竞价结果管理
                    </el-menu-item>
                    <el-menu-item index="4-4-2-3"
                                  @click="navigatorTo('/holdKanban/holdKanban/transactionManagement/rollingPlan')">
                      滚动撮合结果管理
                    </el-menu-item>
                    <el-menu-item index="4-4-2-4"
                                  @click="navigatorTo('/holdKanban/holdKanban/transactionManagement/spotTransactionManagement')">
                      现货交易管理
                    </el-menu-item>

                  </el-sub-menu>
                </el-menu-item-group>
              </el-sub-menu>
              <el-sub-menu index="11">
                <template #title>
                  交易日历
                </template>
                <el-menu-item-group>
                  <el-menu-item index="11-1"
                                @click="navigatorTo('/transactionCalendar/transactionCalendar/peakFlatValleyManagement')">
                    峰平谷管理
                  </el-menu-item>
                  <el-menu-item index="11-2"
                                @click="navigatorTo('/transactionCalendar/transactionCalendar/priceLimitInformation')">
                    限价信息
                  </el-menu-item>
                  <el-sub-menu index="11-3">
                    <template #title>交易日历</template>
                    <el-menu-item index="11-3-1"
                                  @click="navigatorTo('/transactionCalendar/transactionCalendar/transactionCalendar')">
                      交易日历
                    </el-menu-item>
                    <el-menu-item index="11-3-2"
                                  @click="navigatorTo('/transactionCalendar/transactionCalendar/todayCalendar')">交易日详情
                    </el-menu-item>
                    <el-menu-item index="11-3-3"
                                  @click="navigatorTo('/transactionCalendar/transactionCalendar/calendarManage')">日历管理
                    </el-menu-item>
                  </el-sub-menu>
                </el-menu-item-group>
              </el-sub-menu>
              <el-sub-menu index="10">
                <template #title>
                  市场风向情况
                </template>
                <el-menu-item-group>
                  <el-menu-item index="10-1"
                                @click="navigatorTo('/spotElectricityPriceForecast/marketDirection/spotElectricityPriceForecast')">
                    现货电价预测
                  </el-menu-item>
                  <el-menu-item index="10-2"
                                @click="navigatorTo('/spotElectricityPriceForecast/marketDirection/electricityPriceFluctuation')">
                    电价波动
                  </el-menu-item>
                  <el-menu-item index="10-3"
                                @click="navigatorTo('/spotElectricityPriceForecast/marketDirection/spotSimilarDate')">
                    现货相似日
                  </el-menu-item>
                </el-menu-item-group>
              </el-sub-menu>
              <el-sub-menu index="5">
                <template #title>
                  客户管理
                </template>
                <el-menu-item-group>
                  <el-menu-item index="5-1"
                                @click="navigatorTo('/customerManagement/myCustomerMiddleman/allCustomers')">全部客户
                  </el-menu-item>
                  <el-menu-item index="5-2"
                                @click="navigatorTo('/customerManagement/myCustomerMiddleman/allMiddleman')">全部居间商
                  </el-menu-item>
                  <el-menu-item index="5-3"
                                @click="navigatorTo('/customerManagement/middlemanSettlement')">居间商结算
                  </el-menu-item>
                </el-menu-item-group>
              </el-sub-menu>
              <el-sub-menu index="6">
                <template #title>
                  结算管理
                </template>
                <el-menu-item-group>
                  <el-menu-item index="5-1"
                                @click="navigatorTo('/settlementManagement/settlementManagement/NissinFoodProducts')">
                    日清月结
                  </el-menu-item>

                </el-menu-item-group>
              </el-sub-menu>
              <el-sub-menu index="7">
                <template #title>
                  历史用电量
                </template>
                <el-menu-item-group>
                  <el-menu-item index="7-1"
                                @click="navigatorTo('/HistoricalElectricityConsumption/HistoricalElectricityConsumption/HistoricalElectricityConsumption')">
                    历史用电量
                  </el-menu-item>

                </el-menu-item-group>
              </el-sub-menu>
              <el-sub-menu index="8">
                <template #title>
                  现货交易复盘
                </template>
                <el-menu-item-group>
                  <el-sub-menu index="8-1">
                    <template #title>方案复盘</template>
                    <el-menu-item index="8-1-1"
                                  @click="navigatorTo('/planReview/planReview/plan')">现货交易方案
                    </el-menu-item>
                    <el-menu-item index="8-1-2"
                                  @click="navigatorTo('/planReview/planReview/replay')">现货交易复盘
                    </el-menu-item>
                  </el-sub-menu>
                </el-menu-item-group>
              </el-sub-menu>
              <el-sub-menu index="9-1">
                <template #title>测算分时段报价</template>
                <el-menu-item index="9-1-1"
                              @click="navigatorTo('/estimateDaypartingQuotation/estimateQuotation/measurementRecord')">
                  测算记录
                </el-menu-item>
                <el-menu-item index="9-1-2"
                              @click="navigatorTo('/estimateDaypartingQuotation/estimateQuotation/createCalculation')">
                  新增测算
                </el-menu-item>
              </el-sub-menu>
            </el-menu>
          </el-scrollbar>
        </div>
      </div>
      <div class='layout-main flex flex-1 flex-col overflow-x-hidden overflow-y-auto'>
        <!-- <div
        class='layout-main-tags h-8 leading-8 text-sm text-gray-600 relative'
    >
        <layout-tags />
    </div> -->
        <div class='layout-main-content flex-1 overflow-hidden'>
          <div style="height:90%;width:100%;">
            <div id="subapp-viewport"></div>
          </div>
          <router-view></router-view>
        </div>
      </div>


    </div>
  </div>

</template>

<script>
import {defineComponent} from 'vue'
import LayoutNavbar from '@/layout/components/navbar.vue'
import router from '@/router/index'
import actions from "@/action";
import {registerMicroApps, start} from 'qiankun';

export default defineComponent({
  name: 'Layout',

  setup() {
    // const waitForContainer = () => {
    //   console.log("232")
    //   if (!document.querySelector('#subapp-viewport')) {
    //     setTimeout(waitForContainer, 100);
    //   } else {
    //     // 容器元素存在，可以进行一些操作
    //     loginSuccess()
    //   }
    // };
    // waitForContainer();

    // 定义子应用列表
    const microApps = [
      {
        name: 'enterpriseSearch',
        entry: 'http://127.0.0.1:31100',
        container: '#subapp-viewport',
        activeRule: '/enterpriseSearch',
      },
      {
        name: 'energyTesting',
        entry: 'http://127.0.0.1:31101',
        container: '#subapp-viewport',
        activeRule: '/energyTesting',
        props: {
          actions,
          routerBase: '/energyTesting', // 子应用的路由前缀(router的base)
          routerList: [], // 子应用的路由列表
        },

      },
      {
        name: 'transactionCalendar',
        entry: 'http://127.0.0.1:31102',
        container: '#subapp-viewport',
        activeRule: '/transactionCalendar',
        props: {
          routerBase: '/transactionCalendar', // 子应用的路由前缀(router的base)
          routerList: [], // 子应用的路由列表
        },

      },
      {
        name: 'spotElectricityPriceForecast',
        entry: 'http://127.0.0.1:31103',
        container: '#subapp-viewport',
        activeRule: '/spotElectricityPriceForecast',
        props: {
          routerBase: '/spotElectricityPriceForecast', // 子应用的路由前缀(router的base)
          routerList: [], // 子应用的路由列表
        },

      },
      {
        name: 'holdKanban',
        entry: 'http://127.0.0.1:31104',
        container: '#subapp-viewport',
        activeRule: '/holdKanban',
        props: {},

      },
      {
        name: 'estimateDaypartingQuotation',
        entry: 'http://127.0.0.1:31105',
        container: '#subapp-viewport',
        activeRule: '/estimateDaypartingQuotation',
      },
      {
        name: 'planReview',
        entry: 'http://127.0.0.1:31106',
        container: '#subapp-viewport',
        activeRule: '/planReview',
      },
      {
        name: 'customerManagement',
        entry: 'http://127.0.0.1:31107',
        container: '#subapp-viewport',
        activeRule: '/customerManagement',
      },
      {
        name: 'settlementManagement',
        entry: 'http://127.0.0.1:31108',
        container: '#subapp-viewport',
        activeRule: '/settlementManagement',
      },
      {
        name: 'HistoricalElectricityConsumption',
        entry: 'http://127.0.0.1:31109',
        container: '#subapp-viewport',
        activeRule: '/HistoricalElectricityConsumption',
      },
    ]

    // const loginSuccess = () => {
    //   console.log("1231231com")
    //   // 获取用户信息和配置信息
    //   const user = {id: '123', name: 'Alice'};
    //   const userConfig = {apps: ['enterpriseSearch']};
    //   // 根据用户配置信息过滤子应用
    //   const apps = microApps.filter(app => userConfig.apps.includes(app.name));
    //   // 注册子应用
    //   registerMicroApps(apps, {
    //     beforeLoad: async (app) => {
    //       console.log(app)
    //       console.log(`Before ${app.name} load`);
    //       await new Promise((resolve) => {
    //         const timer = setInterval(() => {
    //           if (document.querySelector('#subapp-container')) {
    //             clearInterval(timer);
    //             // 启动 qiankun
    //
    //             resolve();
    //           }
    //         }, 100);
    //       });
    //     },
    //     beforeMount: app => {
    //       console.log(`Before ${app.name} mount`);
    //     },
    //     afterUnmount: app => {
    //       console.log(`After ${app.name} unmount`);
    //     }
    //   });
    //   start();
    //   // 跳转到默认子应用
    //   const defaultApp = apps[0];
    //   location.hash = defaultApp.activeRule;
    //
    // }
    // // 监听登录事件，并在登录成功后启动 qiankun 并跳转到默认子应用
    // document.addEventListener('loginSuccess', loginSuccess);

    const navigatorTo = (url) => {
      let token = localStorage.getItem('token')
      let to = url
      if (!token) {
        router.push({
          path: '/login', query: {
            form: to
          }
        })
        return
      } else {
        router.push(to)
        //  console.log(url)
        // // navigateTo(url);

        /* 方法2  可以解决 但是不丝滑*/
        // history.pushState(null, url, url)
        // location.reload();
        /* 方法1  可以解决 但是不丝滑*/
        //  //window.top.location.href = url;  可以解决 但是不丝滑
      }
    }
    return {
      navigatorTo

    }
  }
})
</script>

<style lang="postcss">
.top_header {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
}

.top_header .logo {
  font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
  font-weight: 650;
  font-style: normal;
  color: #333;
  font-size: 18px;
  margin: 0 20px;
}

.top_header .text {
  font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
  font-weight: 650;
  font-style: normal;
  color: #FFFFFF;
  font-size: 18px;
}

#subapp-viewport {
  height: 100%;
  width: 100%;

&
> div {
  height: 100%;
  width: 100%;
}

}

#home {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  width: 100%;
}

#nav {
  padding: 30px;

a {
  font-weight: bold;
  color: #2c3e50;

&
.router-link-exact-active {
  color: #42b983;
}

}
}

.h-121 {
  height: 4rem
}

.layout-main-navbar1 {
  background: url(/src/assets/img/bg_nav.png);
  background-color: #1890FF;
  color: #fff;
}

.layoutSidebar_style {
  height: calc(100vh - 4rem);
}
</style>
