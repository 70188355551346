<template>
  <div class="edit-personal-info">
    <!-- 标题 -->
    <div class="edit-personal-info-content assistBg">
      <el-tabs v-model="activeName" @tab-click="handleClick" v-loading="loading">
        <el-tab-pane :label=elTabPane[0].label :name="elTabPane[0].name">
          <edit-info :user-info="userInfo" @uploadInfo="uploadInfo"/>
        </el-tab-pane>
        <el-tab-pane :label=elTabPane[1].label :name="elTabPane[1].name">
          <edit-password @uploadPassword="uploadPassword"/>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
  import editInfo from './component/editInfo'
  import editPassword from './component/editPassword'
  import { getUserInfo } from "@/api/UserInfo"
  export default {
    components: { // 解构映射到组件
      editInfo,
      editPassword
    },
    props:{
      userInfo:{
        type:Object,
        default:{}
      }
    },
    data() { // 初始化数据
      return {
        activeName: 'editInfo',
        currentText: '',
        elTabPane:[
          {
            label: '基本资料',
            name: 'editInfo',
          },
          {
            label: '修改密码',
            name: 'editPassword',
          }
        ],
        loading: false,
      }
    },
    created() {
      // this.getParams()
      // this.getUserInfoApi()
    },
    methods: {
      // 获取路由参数
      getParams() {
        const activeName = this.$route.query.type
        const result = this.elTabPane.filter(item => item.name === activeName)
        if (result.length) {
          this.activeName = result[0].name
          this.currentText = result[0].label
          return
        }
        this.activeName = this.elTabPane[0].name
        this.currentText = this.elTabPane[0].label
      },
      handleClick(tab) {
        this.currentText = this.elTabPane.filter(item => item.name === tab.name)[0].label
        this.$router.push({ path: this.$route.path, query:{ type:tab.name }})
      },
      uploadInfo({loading}) {
        this.loading = loading
        this.$emit('uploadInfo')
        // this.getUserInfoApi()
      },
      uploadPassword({loading}) {
        this.loading = loading
      },
      // getUserInfoApi(){
      //   getUserInfo({username:localStorage.getItem('name')}).then(res =>{
      //     if (res.code === 200) {
      //       this.userInfo =res.data.sysUser
      //       this.userInfo.areaArr =(res.data.sysUser.area)?(res.data.sysUser.area).split('-'):[]
      //     }
      //   })
      // },
    },

  }
</script>
<style scoped lang='less'>
.edit-personal-info{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-color);
  .edit-personal-info-content{
    margin-top: 20px;
    flex-grow: 1;
  }
  /deep/.el-tabs__item{
    color: var(--text-color)!important;
  }
  /deep/.el-tabs__item.is-active{
    color: var(--public-color)!important;
  }
}
</style>
