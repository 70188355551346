<template>
  <div class="page">
    <div class="layout-main-navbar1 flex justify-between items-center h-121 shadow-sm overflow-hidden relative z-10">
      <div class="flex items-center px-4 flex-wrap h-121">
        <img v-if="!imgUrl||imgUrl==='null'" src="../../assets/img/icon.png" alt="" style="margin-right:10px;width: 40px;">
        <img :src="imgUrl" v-if="imgUrl||imgUrl!=='null'" alt="" style="margin-right:10px; height: 30px">
        <div class="layout-sidebar-logo relative" style="line-height: 19px;">
          <span class="titleFamily">{{ `${chooseTenantName}${appName ? ' · ' + appName : ''}` || "电力交易辅助决策平台" }}</span>
          <p class="f_12">{{ `${senName}${appNameEnName ? ' · ' + appNameEnName : ''}` }}</p>
          <p></p>
        </div>
      </div>
      <div class="flex items-center flex-row-reverse px-4 min-width-32">
        <el-dropdown @command='handleOptins($event)' popper-class="classTestwyc">
                          <span class="el-dropdown-link">
                            <el-avatar :size="24" style="width: 24px;height: 24px;"
                                       :src="headerImg? headerImg : 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'"></el-avatar>
                            <el-icon class="el-icon--right">
                              <arrow-down/>
                            </el-icon>
                          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="userInfo">个人中心</el-dropdown-item>
              <el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-tooltip content="回到主页" placement="bottom">
          <img src="../../assets/img/home.jpg" @click="goHome" alt=""
               style="margin-right:10px;width: 20px;margin-bottom: 5px;">
        </el-tooltip>
        <div class="dateRightTopBox">
            <img src="../../assets/img/time.png" alt="">
            <span>{{ dateStrRightTop }}</span>
        </div>

      </div>
    </div>
    <div class='layout flex '>
      <div class='layout-sidebar  bg-white flex flex-col layoutSidebar_style transition-width duration-200  menu-shadow mr10 w-64'>
        <div class='layout-sidebar-menubar flex flex-1 overflow-hidden'>
          <el-scrollbar wrap-class='scrollbar-wrapper'>
            <el-menu mode='vertical' class="w-64" :collapse-transition='false' id="menuIds"
                     :default-active="defaultMenuId" :default-openeds="defaultMenuGroup">
              <div v-for="(item,index) in menuList" :key="index">
                <el-sub-menu :index="index"
                             v-if='item.childrenMenuList && item.childrenMenuList.length > 0'>
                  <template #title>
                    {{ item.menuName }}
                  </template>
                  <el-menu-item-group v-if="item.childrenMenuList&&item.childrenMenuList.length">
                    <el-menu-item :index="index+','+idx"
                                  v-for="(itm,idx) in item.childrenMenuList" :key="itm.path?itm.path:index+','+idx"
                                  :data-url="itm.path"
                                  @click="navigatorTo(itm,index+','+idx,index)">{{
                        itm.menuName
                      }}
                    </el-menu-item>
                  </el-menu-item-group>
                </el-sub-menu>
                <el-menu-item v-else :key="item.path?item.path:index" :index='index' :data-url="item.path"
                              @click="navigatorTo(item,index,index)">
                  <template #title>
                    {{ item.menuName }}
                  </template>
                </el-menu-item>
              </div>
            </el-menu>
          </el-scrollbar>
        </div>
      </div>
      <div class='layout-main flex flex-1 flex-col overflow-x-hidden overflow-y-auto'>
        <div class='layout-main-content flex-1 overflow-hidden'>
            <div id="subapp-viewport"></div>
          <router-view></router-view>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import actions from "@/action";
import store from "@/store";
import {getUserInfo} from "@/api/UserInfo";
import {mapState} from "vuex";

export default {
  name: "qiankunMenu",
  computed: {
    ...mapState([
      "headerImg",
    ]),
  },
  props:{
    defaultMenuId:{
      type:String,
      default: ''
    },
    chooseTenantName:{
      type:String,
      default: ''
    },
    appName:{
      type:String,default: ''
    },
    senName:{
      type:String,default: ''
    },
    imgUrl:{
      type:String,default: ''
    },
    appNameEnName:{
      type:String,default: ''
    },
    menuList:{
      type:Array,
      // default: []
    },
    defaultMenuGroup:{
      type:Array,default: []
    },
  },
  data(){
    return{
      userAvatar:'',
      dateStrRightTop: '',
      dateRightTopInterval: null
    }
  },
  mounted() {
    let token = localStorage.getItem('token')
    if (token) {
      //this.getInfo()
    }
    this.dateRightTopChange()
  },
  unmounted() {
    clearInterval(this.dateRightTopInterval)
  },
  methods:{
    getInfo(){
      //localStorage.getItem('name')
      getUserInfo({username:localStorage.getItem('name')}).then(res=>{
        this.userAvatar = res.data.sysUser.photo ?  process.env.VUE_APP_URL_IMG  + res.data.sysUser.photo : ''
      })
    },
    goHome(text) {

      this.$emit('goHome',text)
    },
    handleOptins(text) {
      this.$emit('handleOptins',text)
    },
    navigatorTo(row, id, groupId) {
      this.$emit('navigatorTo',row, id, groupId)
    },
    dateRightTopChange() {
      const now = new Date()
      const year = now.getFullYear()
      const month = (now.getMonth()+1) > 9 ? (now.getMonth()+1) : '0' + (now.getMonth()+1)
      const today = now.getDate() > 9 ? now.getDate() : '0' + now.getDate()
      const hour = now.getHours() > 9 ? now.getHours() : '0' + now.getHours();
      const minute = now.getMinutes() > 9 ? now.getMinutes() : '0' + now.getMinutes(); // 获取分钟
      const second = now.getSeconds() > 9 ? now.getSeconds() : '0' + now.getSeconds(); // 获取秒数
      const weekday = now.getDay(); // 获取周几（注意周日返回的是0，周一返回的是1，以此类推）
      let weekDayStr
      switch(weekday) {
          case 0: weekDayStr = '星期日'
              break;
          case 1: weekDayStr = '星期一'
              break;
          case 2: weekDayStr = '星期二'
              break;
          case 3: weekDayStr = '星期三'
              break;
          case 4: weekDayStr = '星期四'
              break;
          case 5: weekDayStr = '星期五'
              break;
          case 6: weekDayStr = '星期六'
              break;
      }
      this.dateStrRightTop = year + '/' + month + '/' + today + ' ' + weekDayStr + ' ' + hour + ':' + minute + ':' + second
      this.dateRightTopInterval = setInterval(() => {
        const now = new Date()
        const year = now.getFullYear()
        const month = (now.getMonth()+1) > 9 ? (now.getMonth()+1) : '0' + (now.getMonth()+1)
        const today = now.getDate() > 9 ? now.getDate() : '0' + now.getDate()
        const hour = now.getHours() > 9 ? now.getHours() : '0' + now.getHours();
        const minute = now.getMinutes() > 9 ? now.getMinutes() : '0' + now.getMinutes(); // 获取分钟
        const second = now.getSeconds() > 9 ? now.getSeconds() : '0' + now.getSeconds(); // 获取秒数
        const weekday = now.getDay(); // 获取周几（注意周日返回的是0，周一返回的是1，以此类推）
        let weekDayStr
        switch(weekday) {
            case 0: weekDayStr = '星期日'
                break;
            case 1: weekDayStr = '星期一'
                break;
            case 2: weekDayStr = '星期二'
                break;
            case 3: weekDayStr = '星期三'
                break;
            case 4: weekDayStr = '星期四'
                break;
            case 5: weekDayStr = '星期五'
                break;
            case 6: weekDayStr = '星期六'
                break;
        }
        this.dateStrRightTop = year + '/' + month + '/' + today + ' ' + weekDayStr + ' ' + hour + ':' + minute + ':' + second
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.page{
  height: 100vh;
  width: 100vw;
  background: #F4F9FF;
}
.bg-white{
  background: #fff;
}
.layout-main{
  padding:20px 20px 20px 10px;
  background: #F4F9FF;
}
.menu-shadow{
  box-shadow: 0px 0px 6px 1px rgba(3,75,143,0.1608);
}
.dateRightTopBox {
  margin-right: 20px;
  font-family: Kingsoft_Cloud_Font, Kingsoft_Cloud_Font;
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 0px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
  }
}
</style>

<style>
.el-menu-item.is-active {
  color: var(--el-menu-active-color)!important;
}
.classTestwyc {
  display: inline-block;
  padding: 10px 20px;
  width: 88px;
  height: 76px;
}

.classTestwyc .el-dropdown__list {
  height: calc(100%);
}

.classTestwyc .el-dropdown-menu {
  height: 100%;
  width: 100%;
  margin: 0;
}

.classTestwyc .el-dropdown-menu .el-dropdown-menu__item {
  /* height: 100%; */
  width: 100%;
  line-height: 22px;
  padding-left: 0px;
  padding-right: 0px;
  text-align: center;
  margin: 0px;
  display: block;
  padding-bottom: 3px;
  padding-top: 3px;
}

</style>
