import layout from '@/layout/index';
import login from '@/views/login';
import register from '@/views/User/register';
import retrievePassword from '@/views/User/retrievePassword';
import userInfo from '@/views/User/userInfo';
import additionInfo from '@/views/User/additionalInfo.vue';
import home from '@/views/Home.vue';
import login_SJ from '@/views/login_SJ.vue';
import page404 from '@/views/login_SJ.vue';
import _ from 'lodash'
import {createRouter, createWebHistory} from 'vue-router'
// import { next } from 'cheerio/lib/api/traversing';

//路由数组
const routes = [
    {path: '/', name: 'home'},
    {path: '/login', name: 'login', component: login},
    {path: '/register', name: 'register', component: register},
    {path: '/additionInfo', name: 'register', component: additionInfo},
    {path: '/retrievePassword', name: 'retrievePassword', component: retrievePassword},
    {path: '/userInfo', name: 'userInfo', component: userInfo},
    {path: '/loginsj', name: 'loginsj', component: login_SJ},
    {path: '/404', name: 'page404', component: page404},
    /* 配置各种应用*/
    // { path: '/holdKanban', name: 'Home', app: 'holdKanban' },
    // { path: '/planReview', name: 'About', app: 'planReview' }
]

//路由对象
const router = createRouter({
    history: createWebHistory(),
    routes //上面的路由数组
})

function checkPathExists(menuList, targetPath) {
    for (let item of menuList) {
        if (item.path === targetPath) {
            return true;
        }
        if (item.childrenMenuList) {
            if (checkPathExists(item.childrenMenuList, targetPath)) {
                return true;
            }
        }
    }
    return false;
}

// router.beforeEach(async (to, from, next) => {
//     if (_.isEmpty(history.state.current)) {
//         _.assign(history.state, {current: from.fullPath});
//     }
//     let routerList = JSON.parse(localStorage.getItem("routerList"))
//     if (!checkPathExists(routerList,to.fullPath)&&to.fullPath!=='/'&&to.fullPath!=='/userInfo') {
//         // 路由无效，重定向到 404 页面
//         next('/404')
//     } else {
//         next()
//     }
//
// })

router.beforeEach(async (to, from, next) => {
    let row = localStorage.getItem('menuList')?JSON.parse(localStorage.getItem('menuList')):{}
    // 这里先去记录一下展示的信息
    let newObj = {
        "menuId":  row.menuId,
        parentName: row.parentName,
        "menuName": row.menuName,
        "path": to.path,
        "orderNo": 0,
        "valid": "0",
    }
    localStorage.setItem('menuListDefault', JSON.stringify(newObj))
    if (_.isEmpty(history.state.current)) {
        _.assign(history.state, { current: from.fullPath });
    }
    next()
})

//导出路由对象，在main.js中引用
export default router
