import './public-path';
import "./registerMicroAppsConfig"
import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import router from './router';
import store from './store';
import 'element-plus/theme-chalk/display.css'
import 'element-plus/dist/index.css'
import { registerMicroApps, start } from 'qiankun';

import '@/assets/css/index.css'
let routes = null;
let instance = null;
let history = null;
import ElementPlus from 'element-plus'
// if (process.env.NODE_ENV !== 'development') { // process是Node环境全部变量, 运行时根据敲击的命令不同, 脚手架会取环境变量给env添加属性和值
//     console.log = function () {
//     }
//     console.error = function () {
//     }
//     console.dir = function () {
//     }
// }

import actions from "@/action";
// const  microApps=   [
//     {
//       name: 'enterpriseSearch',
//       entry: 'http://127.0.0.1:31100',
//       container: '#subapp-viewport',
//       activeRule: '/enterpriseSearch',
//     },
//     {
//       name: 'energyTesting',
//       entry: 'http://127.0.0.1:31101',
//       container: '#subapp-viewport',
//       activeRule: '/energyTesting',
//       props:{
//         actions,
//         routerBase: '/energyTesting', // 子应用的路由前缀(router的base)
//         routerList: [], // 子应用的路由列表
//       },
//
//     },
//     {
//       name: 'transactionCalendar',
//       entry: 'http://127.0.0.1:31102',
//       container: '#subapp-viewport',
//       activeRule: '/transactionCalendar',
//       props:{
//         routerBase: '/transactionCalendar', // 子应用的路由前缀(router的base)
//         routerList: [], // 子应用的路由列表
//       },
//
//     },
//     {
//       name: 'spotElectricityPriceForecast',
//       entry: 'http://127.0.0.1:31103',
//       container: '#subapp-viewport',
//       activeRule: '/spotElectricityPriceForecast',
//       props:{
//         routerBase: '/spotElectricityPriceForecast', // 子应用的路由前缀(router的base)
//         routerList: [
//
//         ], // 子应用的路由列表
//       },
//
//     },
//     {
//       name: 'holdKanban',
//       entry: 'http://127.0.0.1:31104',
//       container: '#subapp-viewport',
//       activeRule: '/holdKanban',
//       props:{},
//
//     },
//     {
//       name: 'estimateDaypartingQuotation',
//       entry: 'http://127.0.0.1:31105',
//       container: '#subapp-viewport',
//       activeRule: '/estimateDaypartingQuotation',
//     },
//     {
//       name: 'planReview',
//       entry: 'http://127.0.0.1:31106',
//       container: '#subapp-viewport',
//       activeRule: '/planReview',
//     },
//     {
//       name: 'customerManagement',
//       entry: 'http://127.0.0.1:31107',
//       container: '#subapp-viewport',
//       activeRule: '/customerManagement',
//     },
//     {
//       name: 'settlementManagement',
//       entry: 'http://127.0.0.1:31108',
//       container: '#subapp-viewport',
//       activeRule: '/settlementManagement',
//     },
//     {
//       name: 'HistoricalElectricityConsumption',
//       entry: 'http://127.0.0.1:31109',
//       container: '#subapp-viewport',
//       activeRule: '/HistoricalElectricityConsumption',
//     },
//   ]
//
// const waitForContainer = () => {
//   console.log("232")
//   if (!document.querySelector('#subapp-viewport')) {
//     setTimeout(waitForContainer, 100);
//   } else {
//     // 容器元素存在，可以进行一些操作
//     loginSuccess()
//   }
// };
// waitForContainer()
// function loginSuccess() {
//   console.log("1231231com")
//   // 获取用户信息和配置信息
//   const user = {id: '123', name: 'Alice'};
//   const userConfig = {apps: ['enterpriseSearch']};
//   // 根据用户配置信息过滤子应用
//   const apps = microApps.filter(app => userConfig.apps.includes(app.name));
//   // 注册子应用
//   registerMicroApps(apps, {
//     beforeLoad: async (app) => {
//       console.log(`Before ${app.name} load`);
//       await new Promise((resolve) => {
//         const timer = setInterval(() => {
//           if (document.querySelector('#subapp-container')) {
//             clearInterval(timer);
//             resolve();
//           }
//         }, 100);
//       });
//     },
//     beforeMount: app => {
//       console.log(`Before ${app.name} mount`);
//     },
//     afterUnmount: app => {
//       console.log(`After ${app.name} unmount`);
//     }
//   });
//   // 启动 qiankun
//   start();
//   // 跳转到默认子应用
//   const defaultApp = apps[0];
//   location.hash = defaultApp.activeRule;
// }
// // 监听登录事件，并在登录成功后启动 qiankun 并跳转到默认子应用
// document.addEventListener('loginSuccess', waitForContainer);

function render(props = {}) {
  const { container } = props;
  history = createWebHistory(window.__POWERED_BY_QIANKUN__ ? '/' : '/');
  instance = createApp(App);
  instance.use(ElementPlus)
  instance.use(router);
  instance.use(store);
  instance.mount(container ? container.querySelector('#appf') : '#appf');
}

if (!window.__POWERED_BY_QIANKUN__) {
  render();
}

export async function bootstrap() {
  console.log('%c ', 'color: green;', 'vue3.0 app bootstraped');
}

function storeTest(props) {
  props.onGlobalStateChange &&
    props.onGlobalStateChange(
      (value, prev) => console.log(`[onGlobalStateChange - ${props.name}]:`, value, prev),
      true,
    );
  props.setGlobalState &&
    props.setGlobalState({
      ignore: props.name,
      user: {
        name: props.name,
      },
    });
}

export async function mount(props) {
  storeTest(props);
  render(props);
  instance.config.globalProperties.$onGlobalStateChange = props.onGlobalStateChange;
  instance.config.globalProperties.$setGlobalState = props.setGlobalState;
}

export async function unmount() {
  instance.unmount();
  instance._container.innerHTML = '';
  instance = null;
  routes = null;
  history.destroy();
}
